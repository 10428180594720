<template>
  <b-tooltip
    :target="target"
    class="w-100"
    placement="right"
    variant="dark"
    triggers="hover"
  >
    <b-table-simple table-variant="dark" class="m-0 p-0">
      <b-tbody>
        <b-tr v-for="(item, key) in items" :key="key">
          <b-td>{{ key }}</b-td>
          <b-td>{{ item }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-tooltip>
</template>

<script>
export default {
    props: {
        items: Object,
        target: String
    }
};
</script>

<style>
.tooltip-inner {
  max-width: unset;
}
</style>