<script>
import Layout from "../../layouts/main";
import FeeManagement from './fee-management.vue';
import FileManagement from './file-management.vue';
export default {
    components: {
        Layout,
        FeeManagement,
        FileManagement
    },
    data() {
        return {
            activeTab: "fee"
        }
    },
    created() {
        if(this.$route.query.activeTab) {
            this.activeTab = this.$route.query.activeTab;
        }
    }
}
</script>

<template>
    <Layout>
        <div class="menu-content">
            <div class="toggle-tabs">
                <b-btn @click="() => activeTab = 'fee'" :variant="activeTab === 'fee' ? 'primary' : 'white'">Manage Fee</b-btn>
                <b-btn @click="() => activeTab = 'file'" :variant="activeTab === 'file' ? 'primary' : 'white'">Configure File</b-btn>
            </div>
        </div>
        <fee-management v-if="activeTab === 'fee'" />
        <file-management v-if="activeTab === 'file'" />
    </Layout>
</template>

<style lang="scss" scoped>
.menu-content {
    padding: 12px;
}
::v-deep .toggle-tabs {
    border-radius: 12px;
    border: 1px solid #DCDAD5;
    background: #FFF;
    padding: 4px;
    display: flex;
    gap: 10px;

    .btn {
        padding: 8px 12px !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 350 !important;
        line-height: 20px !important;
        border-radius: var(--8, 8px) !important;
        &:focus {
            box-shadow: none !important;
        }
    }

}
</style>