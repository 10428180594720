<script>
import * as cloneDeep from "lodash.clonedeep";
import { required, minValue, maxValue, requiredIf } from "vuelidate/lib/validators";

import {
  FETCH_FEE_CONFIG_META,
  FETCH_FEE_CONFIG_RECORDS,
  FETCH_ALL_PAYMENT_CHANNEL,
  FETCH_PAYMENT_METHODS_OF_PAYMENT_CHANNEL,
  FETCH_TOLERANCE_LEVEL
} from "@/state/actions.type";
import {
  UPDATE_FEE_CONFIG_PSP_EMAIL_DETAILS_IN_STORE,
  UPDATE_FEE_CONFIG_SFTP_DETAILS_IN_STORE,
  UPDATE_FEE_CONFIG_TOLERANCE_LEVEL_IN_STORE,
  UPDATE_FEE_CONFIG_RECORD_IN_STORE,
  UPDATE_FOOD_TECH_FEE_CONFIG_RECORD_IN_STORE,
  CREATE_FEE_CONFIG_RECORD_IN_STORE,
  CREATE_FOOD_TECH_FEE_CONFIG_RECORD_IN_STORE,
  // DELETE_FEE_CONFIG_RECORD_IN_STORE
} from "@/state/mutations.type";
import Multiselect from 'vue-multiselect';
// import Swal from "sweetalert2";
// import SearchBy from '../../../components/search-by.vue';
import SwitchInput from '../../../components/switch-input.vue';
import PerPage from '../../../components/per-page.vue';
import Common from "../../../helpers/Common";

export default {
  components: {
    Multiselect,
    // SearchBy,
    SwitchInput,
    PerPage
  },
  data() {
    return {
      feeManagementData: [],
      paymentChannelList: [],
      currentPage: 1,
      perPage: 10,
      fields: [
        { key: "is_enabled", label: "Status" },
        // { key: "name", label: "Name" },
        { key: "payment_method_name", label: "views.fee_management.table.pm_name" },
        { key: "payment_network_name", label: "Network" },
        { key: "currency", label: "views.fee_management.table.currency" },
        { key: "bank_name", label: "Bank", tdClass: "d-none", thClass: "d-none" },
        { key: "installment_term", label: "Installment Term", tdClass: "d-none", thClass: "d-none" },
        { key: "percent_fee", label: "views.fee_management.table.percent_fee" },
        { key: "minimum_fee", label: "views.fee_management.table.minimum_fee" },
        { key: "flat_fee", label: "views.fee_management.table.flat_fee" },
        { key: "charge_key", label: "Applied On", tdClass: "d-none", thClass: "d-none" },
        { key: "charge_type", label: "Fee Type", tdClass: "d-none", thClass: "d-none" },
        // { key: "settlement_days", label: "views.fee_management.table.settlement_days" },
        { key: "percent_tax", label: "views.fee_management.table.tax_percentage" },
        { key: "rules", label: "Fee Rules" },
        // { key: "is_commercial", label: "Commercial" },
        { key: "show_details", label: '', stickyColumn: true }
      ],
      selectedPSP: null,
      showAddEmailModal: false,
      showSFTPModal: false,
      tolerance_level: 0,
      feeConfig: {
        "merchant_email": "",
        "merchant_psp_email": "",
        "sftp_link": "",
        "sftp_username": "",
        "sftp_password": "",
        "is_psp_email_configured": false,
        "is_sftp_configured": false
      },
      form: {
        paymentMethod: null,
        // currency: '',
        percentFee: null,
        minimumFee: null,
        flatFee: null,
        settlementCycleDays: null,
        tax: null,
        // is_commercial: false,
        // status: false,
        network_id: [],
        rules: {},
        bank_code: {
          terms: []
        },
        bank_name: "",
        installment_term: null,
        currencies: []
      },
      foodTechForm: {
        fee_type: '',
        applied_on: '',
        // is_apply_fee_enabled: false,
        flat_fee: null,
        percent_fee: null,
        pm_key: '',
        rules: {},
        currencies: []
      },
      isSubmited: false,
      currencyOptions: ['Rest of all', ...Common.currencyList()],
      networkOptions: [],
      amountRules: false,
      feesParams: {
        "gt": "Greater Than",
        "lt": "Less Than",
        "eq": "Equal To",
      },
      feesRules: {},
      showFeeRulesModal: false,
      amountRuleError: "",
      showInstallment: false,
      // searchBy: {
      //   key: "file_id",
      //   value: ""
      // },
      // searchByOptions: ['File Id'],
      timeout: null,
      isFetching: false,
      chargeKeys: {},
      chargeTypes: {}
    };
  },
  validations: {
    form: {
      paymentMethod: {
        required
      },
      // currency: {
      //   required
      // },
      percentFee: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(100)
      },
      currencies: {},
      minimumFee: {
        // required
      },
      flatFee: {
        required,
        minValue: minValue(0)
      },
      // settlementCycleDays: {
      //   required
      // },
      tax: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(100)
      },
      // is_commercial: {
      //   required
      // },
      // status: {
      //   required
      // },
      network_id: {
        requiredIf: requiredIf(function() {
          return this.networkOptions.length > 0;
        })
      },
      bank_code: {
        requiredIf: requiredIf(function() {
          return this.showInstallment;
        })
      },
      installment_term: {
        requiredIf: requiredIf(function() {
          return this.showInstallment;
        })
      }
    },
    foodTechForm: {
      fee_type: {
        required
      },
      applied_on: {
        required
      },
      percent_fee: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(100)
      },
      flat_fee: {
        required,
        minValue: minValue(0)
      },
      currencies: {}
      // is_apply_fee_enabled: {},
    }
  },

  created() {
    this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, {
      country: null,
      paymentMethod: null
    }).then((response) => {
      this.paymentChannelList = response.filter((channel) => channel.is_enabled).map(pc => {
        return { name: pc['name'], key: pc['key'], 'channel_type': pc['channel_type'] }
      });
      this.selectedPSP = this.paymentChannelList[0] || null
      if(this.selectedPSP) {
        this.pspChanged();
      }
    })
  },

  computed: {
    feeConfigMeta() {
      return this.$store.state.feeManagement.feeConfigMeta;
    },

    reconEmailFromStore() {
      return this.$store.state.feeManagement.recon_email;
    },

    reconConfirmationCodeFromStore() {
      return this.$store.state.feeManagement.recon_confirmation_code;
    },

    reconConfirmationLinkFromStore() {
      return this.$store.state.feeManagement.recon_confirmation_link;
    },

    paymentMethodsOfPaymentChannel() {
      return this.$store.state.feeManagement.paymentMethods;
    },

    paymentMethodListOfPaymentChannel() {
      return this.$store.state.feeManagement.paymentMethodsList;
    },

    feeManagementDataFromStore() {
      return this.$store.state.feeManagement.content;
    },

    isValidSFTPConfiguration() {
      if (this.feeConfig.sftp_link && this.feeConfig.sftp_username && this.feeConfig.sftp_password) {
        return true;
      } else {
        return false;
      }
    },
    daysList() {
      return Array.from(Array(31).keys())
    },
    appliedOnList() {
      if(this.foodTechForm.fee_type) {
        return this.foodTechForm.fee_type.applied_on;
      }
      return [];
    },
    rows() {
      return this.$store.state.feeManagement.totalContent;
    }
  },

  methods: {
    openAddEmailModal() {
      this.showAddEmailModal = true;
      // this.$refs.merchantEmail.focus();
    },

    hideAddEmailModal() {
      this.showAddEmailModal = false;
    },

    openSFTPModal() {
      this.showSFTPModal = true;
      this.$refs.sftpLink.focus();
    },

    hideSFTPModal() {
      this.showSFTPModal = false;
    },

    updatePSPEmailDetails() {
      this.$store.dispatch(`feeManagement/${UPDATE_FEE_CONFIG_PSP_EMAIL_DETAILS_IN_STORE}`, {
          configData: {
              "merchant_email": this.feeConfig.merchant_email,
              "merchant_psp_email": this.feeConfig.merchant_psp_email
          },
          pcKey: this.selectedPSP.key
      }).then((response) => {
        if(response.status_code == "2000") {
          this.$notify({
            type: 'success',
            text: response.message,
            closeOnClick: true,
          });
        } else {
          this.$notify({
            type: 'error',
            text: response.message,
            closeOnClick: true,
          });
        }
        this.showAddEmailModal = false;
      }).catch((error) => {
        this.$notify({
          type: 'error',
          text: error.message,
          closeOnClick: true,
        });
      });
    },

    updateSFTPDetails() {
      this.$store.dispatch(`feeManagement/${UPDATE_FEE_CONFIG_SFTP_DETAILS_IN_STORE}`, {
        configData: {
          "sftp_link": this.feeConfig.sftp_link,
          "sftp_username": this.feeConfig.sftp_username,
          "sftp_password": this.feeConfig.sftp_password
        },
        pcKey: this.selectedPSP.key
      }).then((response) => {
        if(response.status_code == "2000") {
          this.$notify({
            type: 'success',
            text: response.message,
            closeOnClick: true,
          });
        } else {
          this.$notify({
            type: 'error',
            text: response.message,
            closeOnClick: true,
          });
        }
        this.showSFTPModal = false;
      }).catch((error) => {
        this.$notify({
          type: 'error',
          text: error.message,
          closeOnClick: true,
        });
      });
    },

    updateToleranceLevel() {
      if(this.tolerance_level < 0 || this.tolerance_level > 10 || isNaN(this.tolerance_level)) {
          this.$notify({
              type: 'error',
              text: this.$t("views.fee_management.tolerance_level_msg"),
              closeOnClick: true,
          });
      } else {
        this.$store.dispatch(`feeManagement/${UPDATE_FEE_CONFIG_TOLERANCE_LEVEL_IN_STORE}`, {
          toleranceLevel: this.tolerance_level,
          pcKey: this.selectedPSP.key
        }).then((response) => {
          this.$notify({
            type: 'success',
            text: response.message,
            closeOnClick: true,
          });
        }).catch((error) => {
          const errMsg = JSON.parse(error.message)
          this.$notify({
            type: 'error',
            text: errMsg.message,
            closeOnClick: true,
          });
        });
      }
    },

    doEdit(item) {
      this.$set(item, 'temp', JSON.parse(JSON.stringify(item)))
      this.$set(item, 'editing', true)
      this.$set(item, 'valid', true)
      this.$set(item, 'validity', {
        "percent_fee": true,
        "minimum_fee": true,
        "flat_fee": true,
        // "settlement_days": true,
        "percent_tax": true
      });
    },

    doSave(item) {
      if(!item.valid) {
        return false;
      }
      
      let isDirty = false;
      this.$set(item, 'editing', false);
      for(let key in item.temp) {
        if(item[key] != item.temp[key]) {
          // if(['percent_fee', 'minimum_fee', 'flat_fee', 'settlement_days', 'percent_tax']. includes(key)) {
          //     item[key] = parseFloat(item.temp[key]);
          // } else {
          item[key] = item.temp[key];
          // }
          isDirty = true;
        }
      }

      if(isDirty) {
        delete item.temp;
        delete item.editing;
        delete item.pm_name;

        this.updateConfigData(item, true);
      }
    },
    // doDelete(item) {
    //   Swal.fire({
    //     title: this.$t("common.sure"),
    //     text: this.$t("common.unrevertable"),
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#34c38f",
    //     cancelButtonColor: "#f46a6a",
    //     confirmButtonText: this.$t("common.delete"),
    //     cancelButtonText: this.$t('button.cancel'),
    //   }).then(result => {
    //     if (result.value) {
    //       this.$store.dispatch(`feeManagement/${DELETE_FEE_CONFIG_RECORD_IN_STORE}`, {
    //         data: {
    //           "uuid": item.uuid
    //         },
    //         pcKey: this.selectedPSP.key
    //       }).then((response) => {
    //         if(response.status_code == "2000") {
    //           this.$notify({
    //             type: 'success',
    //             text: response.message,
    //             closeOnClick: true,
    //           });
    //         } else {
    //           this.$notify({
    //             type: 'error',
    //             text: response.message,
    //             closeOnClick: true,
    //           });
    //         }
    //         this.fetchRecords();
    //       }).catch((error) => {
    //         this.$notify({
    //           type: 'error',
    //           text: error.message,
    //           closeOnClick: true,
    //         });
    //       });
    //     }
    //   });
    // },
    doCancel(item) {
      this.$set(item, 'editing', false)
      this.$delete(item, 'temp')
    },
    resetModal() {
      this.form = {
        paymentMethod: null,
        // currency: '',
        percentFee: null,
        minimumFee: '',
        flatFee: '',
        settlementCycleDays: '',
        tax: '',
        // is_commercial: false,
        // status: false,
        network_id: [],
        bank_code: {
          terms: []
        },
        currencies: [],
        bank_name: "",
        installment_term: null,
        rules: {}
      };

      
      this.amountRules = false;
      this.amountRuleError = "";

      this.showInstallment = false;
      this.networkOptions = [];

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      this.isSubmited = true;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let rules = {};
      if(this.form.rules && this.form.rules.amount_rules && this.form.rules.amount_rules.length > 0) {
        rules = this.form.rules.amount_rules.filter(rule => rule.amount && rule.percent_fee && rule.operator && ['lt', 'gt', 'eq'].includes(rule.operator))
        rules = rules.length > 0 ? { amount_rules: rules } : {};
      }

      const payload = {
        "payment_channel_key": this.selectedPSP.key,
        "payment_method_key": this.form.paymentMethod.value,
        "payment_network_keys": (this.form.network_id.length > 0 && this.form.network_id[0].text !== 'Rest of all') ? this.form.network_id.map(element => element.value) : [],
        "charge_type": "",
        "charge_key": "",
        "percent_fee": this.form.percentFee,
        "flat_fee": this.form.flatFee,
        "minimun_fee": this.form.minimumFee,
        "percent_tax": this.form.tax,
        "tolerance": 0,
        "is_enabled": true,
        "currency": (this.form.currencies.length > 0 && this.form.currencies[0] !== 'Rest of all') ? this.form.currencies : [],
        "bank_code": this.form.bank_name ? this.form.bank_code.bank_code : "",
        "bank_name": this.form.bank_name ? this.form.bank_name : "",
        "installment_terms": this.form.bank_name ? this.form.installment_term : 0,
        "rules": rules
      }

      this.$store.dispatch(`feeManagement/${CREATE_FEE_CONFIG_RECORD_IN_STORE}`, payload).then((response) => {
        // if(response.status_code == "2000") {
        this.$notify({
          type: 'success',
          text: response.message,
          closeOnClick: true,
        });
        // }
        // else {
        //   this.$notify({
        //     type: 'error',
        //     text: response.message,
        //     closeOnClick: true,
        //   });
        // }
        this.isSubmited = false;
        this.amountRules = false;
        this.form.rules = {};
        this.fetchRecords();
        this.$nextTick(() => {
          this.$bvModal.hide('feeManagementModal')
        });
      }).catch((error) => {
        this.$notify({
          type: 'error',
          text: JSON.parse(error.message).message,
          closeOnClick: true,
        });
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    fetchRecords() {
      this.isFetching = true;
      this.feeManagementData = [];

      this.$store.dispatch(`feeManagement/${FETCH_FEE_CONFIG_RECORDS}`, {
        payment_channel_key: this.selectedPSP.key,
        page: this.currentPage,
        page_size: this.perPage
      }).then(() => {
        this.isFetching = false;
        this.feeManagementData = this.feeManagementDataFromStore ? cloneDeep(this.feeManagementDataFromStore) : [];
      }).catch((error) => {
        this.isFetching = false;
        const errMsg = JSON.parse(error.message)
        this.$notify({
          type: 'error',
          text: errMsg.message,
          closeOnClick: true,
        });
      });
    },
    inputHandler(item, field) {
      item.validity[field] = event.target.validity.valid;
      if(Object.values(item.validity).includes(false)) {
        item.valid = false;
      } else {
        item.valid = true;
      }
    },
    applyFeeChanged(value, item) {
      if (value != item.is_enabled) {
        item.is_enabled = value;
        if(item.temp) {
          item.temp.is_enabled = value;
        }
        this.updateConfigData(item, true);
      }
    },

    updateConfigData(item, isFetchData = false) {
      // const payload = {
      //   "ID": item.ID,
      //   "charge_type": item.charge_type,
      //   "charge_key": item.charge_key,
      //   "percent_fee": item.percent_fee,
      //   "flat_fee": item.flat_fee,
      //   "minimum_fee": item.minimum_fee,
      //   "percent_tax": item.percent_tax,
      //   "is_enabled": item.is_enabled,
      //   "bank_code": item.bank_code,
      //   "bank_name": item.bank_name,
      //   "installment_terms": item.installment_terms
      // }

      this.$store.dispatch(`feeManagement/${UPDATE_FEE_CONFIG_RECORD_IN_STORE}`, item).then((response) => {
        this.$notify({
          type: 'success',
          text: response.message,
          closeOnClick: true,
        });
        if(isFetchData) {
          this.fetchRecords();
        }
      }).catch((error) => {
        this.$notify({
          type: 'error',
          text: error.message,
          closeOnClick: true,
        });
      });
    },
    onCopyReconEmail() {
      this.$notify({
        type: "success",
        text: this.$t("views.fee_management.email_copy_msg"),
        closeOnClick: true,
      });
    },
    onCopyConfirmationCode() {
      this.$notify({
        type: "success",
        text: "Confirmation Code copied to clipboard.",
        closeOnClick: true,
      });
    },
    paymentMethodChanged(item) {
      // this.currencyOptions = this.paymentMethodsOfPaymentChannel.currency_data[item.value] ? this.paymentMethodsOfPaymentChannel.currency_data[item.value].currency : [];
      this.form.network_id = [];

      if(this.paymentMethodsOfPaymentChannel.currency_data[item.value] && this.paymentMethodsOfPaymentChannel.currency_data[item.value].type === 'INSTALLMENT') {
        this.form.installment_term = null;
        this.showInstallment = true;
      } else {
        this.showInstallment = false;
        this.form.installment_term = null;
      }

      if(this.paymentMethodsOfPaymentChannel.payment_networks[item.value]) {
        this.networkOptions = [
          { 
            value: "",
            text: "Rest of all"
          },
          ...this.paymentMethodsOfPaymentChannel.payment_networks[item.value].map((network) => {
            return {
              value: network.network_key,
              text: network.network_name
            }
          })
        ];

        if(this.networkOptions.length > 0 && this.form.network_id === 0) {
          this.form.network_id = null;
        }
      } else {
        this.networkOptions = [];
      }
    },
    openAmountRules() {
      if(!this.amountRules) {
        if(this.selectedPSP && (this.selectedPSP.channel_type === 'FOODTECH' || this.selectedPSP.channel_type === 'MARKETPLACE')) {
          this.foodTechForm.rules = {
            amount_rules: [
              {
                "min_amount": null,
                "max_amount": null,
                "percent_fee": null
              }
            ]
          };
        } else {
          this.form.rules = {
            amount_rules: [
              {
                "amount": null,
                "operator": "gt",
                "percent_fee": null
              },
              {
                "amount": null,
                "operator": "lt",
                "percent_fee": null
              },
              {
                "amount": null,
                "operator": "eq",
                "percent_fee": null
              }
            ]
          };
        }
        this.amountRules = true;
      }
    },
    closeAmountRules() {
      this.amountRuleError = "";
      delete this.form.rules;
      this.amountRules = false;
    },

    addMoreRule() {
      this.foodTechForm.rules.amount_rules.push({
        "min_amount": null,
        "max_amount": null,
        "percent_fee": null
      });
    },

    showFeeRules(item) {
      if(!this.showFeeRulesModal) {
        this.feesRules = item.rules;
      } else {
        this.feesRules = {};
      }
      this.showFeeRulesModal = !this.showFeeRulesModal;
    },

    pspChanged() {
      // this.currencyOptions = [];
      this.networkOptions = [];
      this.feeManagementData = [];
      this.tolerance_level = 0;
      this.feeConfig = {
        "merchant_email": "",
        "merchant_psp_email": "",
        "sftp_link": "",
        "sftp_username": "",
        "sftp_password": "",
        "is_psp_email_configured": false,
        "is_sftp_configured": false
      };
      this.foodTechForm.pm_key = this.selectedPSP.key;
      for(let element of this.fields) {
        if(['charge_key', 'charge_type'].includes(element.key)) {
          if(this.selectedPSP.channel_type === 'MARKETPLACE' || this.selectedPSP.channel_type === 'FOODTECH') {
            element.thClass = "";
            element.tdClass = "";
          } else {
            element.thClass = "d-none";
            element.tdClass = "d-none";

          }
        }

        if(['percent_tax', 'minimum_fee', 'payment_method_name', 'payment_network_name'].includes(element.key)) {
          if(this.selectedPSP.channel_type === 'MARKETPLACE' || this.selectedPSP.channel_type === 'FOODTECH') {
            element.thClass = "d-none";
            element.tdClass = "d-none";
          } else {
            element.thClass = "";
            element.tdClass = "";
          }
        }
      }
      this.fetchRecords();

      this.$store.dispatch(`feeManagement/${FETCH_FEE_CONFIG_META}` , {
          pcKey: this.selectedPSP.key
      }).then(() => {
        this.feeConfig = cloneDeep(this.feeConfigMeta);
      }).catch((error) => {
        const errMsg = JSON.parse(error.message)
        this.$notify({
          type: 'error',
          text: errMsg.message,
          closeOnClick: true,
        });
      })

      this.$store.dispatch(`feeManagement/${FETCH_TOLERANCE_LEVEL}`, this.selectedPSP.key)
      .then((response) => {
        this.tolerance_level = response.data;
      }).catch((error) => {
        const errMsg = JSON.parse(error.message)
        this.$notify({
          type: 'error',
          text: errMsg.message,
          closeOnClick: true,
        });
      })

      this.$store.dispatch(`feeManagement/${FETCH_PAYMENT_METHODS_OF_PAYMENT_CHANNEL}` , {
          pcKey: this.selectedPSP.key
      }).then((response) => {
        if(this.paymentMethodsOfPaymentChannel.installment_details && this.paymentMethodsOfPaymentChannel.installment_details.length > 0) {
          for(let element of this.fields) {
            if(['bank_name', 'installment_term'].includes(element.key)) {
              element.thClass = "";
              element.tdClass = "";
  
              element.thClass = "";
              element.tdClass = "";
            }
          }
        } else {
          for(let element of this.fields) {
            if(['bank_name', 'installment_term'].includes(element.key)) {
              element.thClass = "d-none";
              element.tdClass = "d-none";
  
              element.thClass = "d-none";
              element.tdClass = "d-none";
            }
          }
        }

        this.chargeKeys = {};
        this.chargeTypes = {};
        
        if(this.selectedPSP.channel_type === 'MARKETPLACE') {
          response.marketplace_config.map((el) => {
            this.chargeTypes[el.key] = el.display_name;

            el.applied_on.map(e => {
              this.chargeKeys[e.key] = e.display_name;
            });
          })
        }


      }).catch((error) => {
        const errMsg = JSON.parse(error.message)
        this.$notify({
          type: 'error',
          text: errMsg.message,
          closeOnClick: true,
        });
      })
    },

    validateFoodTechState(name) {
      const { $dirty, $error } = this.$v.foodTechForm[name];
      return $dirty ? !$error : null;
    },

    resetFoodTechModal() {
      this.foodTechForm = {
        fee_type: '',
        applied_on: '',
        // is_apply_fee_enabled: false,
        flat_fee: null,
        percent_fee: null,
        pm_key: this.selectedPSP.key,
        rules: {},
        currencies: []
      }

      this.amountRules = false;
      this.amountRuleError = "";
      this.isSubmited = false;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    handleOkFoodTech(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmitFoodTech()
    },

    handleSubmitFoodTech() {
      this.isSubmited = true;
      
      this.amountRuleError = "";
      let rules = {};
      if(this.foodTechForm.rules && this.foodTechForm.rules.amount_rules && this.foodTechForm.rules.amount_rules.length > 0) {
        rules = this.foodTechForm.rules.amount_rules.filter(rule => {
          for(let value of Object.values(rule)) {
            if(typeof value !== 'number' || !(value >= 0)) {
              return false;
            }
          }
          return true;
        });
        if(rules.length !== this.foodTechForm.rules.amount_rules.length) {
          this.amountRuleError = 'All Amount rules should be valid positive intergers.';
        }
        rules = rules.length > 0 ? { amount_rules: rules } : {};
      }
      
      this.$v.foodTechForm.$touch();
      if (this.$v.foodTechForm.$anyError || this.amountRuleError) {
        return;
      }

      const payload = {
        "payment_channel_key": this.selectedPSP.key,
        "payment_method_key": "",
        "payment_network_keys": [],
        "charge_type": this.foodTechForm.fee_type.key,
        "charge_key": this.foodTechForm.applied_on.key,
        "percent_fee": this.foodTechForm.percent_fee,
        "flat_fee": this.foodTechForm.flat_fee,
        "minimun_fee": 0.0,
        "percent_tax": 0.0,
        "tolerance": 0,
        "currency": (this.foodTechForm.currencies.length > 0 && this.foodTechForm.currencies[0] !== 'Rest of all') ? this.foodTechForm.currencies : [],
        "is_enabled": true,
        "bank_code": "",
        "bank_name": "", 
        "installment_terms": 0,
        "rules": rules
      }

      this.$store.dispatch(`feeManagement/${CREATE_FOOD_TECH_FEE_CONFIG_RECORD_IN_STORE}`, payload)
      .then((response) => {
        // if(response.status_code == "2000") {
          this.$notify({
            type: 'success',
            text: response.message,
            closeOnClick: true,
          });
        // } else {
        //   this.$notify({
        //     type: 'error',
        //     text: response.message,
        //     closeOnClick: true,
        //   });
        // }
        this.isSubmited = false;
        this.fetchRecords();
        this.$nextTick(() => {
          this.$bvModal.hide('feeManagementModal')
        });
      }).catch((error) => {
        this.$notify({
          type: 'error',
          text: JSON.parse(error.message).message,
          closeOnClick: true,
        });
      });
    },

    doEditFoodTech(item) {
      this.$set(item, 'temp', JSON.parse(JSON.stringify(item)))
      this.$set(item, 'editing', true)
      this.$set(item, 'valid', true)
      this.$set(item, 'validity', {
        "percent_fee": true,
        "flat_fee": true,
      });
    },

    doSaveFoodTech(item) {
      delete item.editing;
      this.updateFoodTechFeeConfig(item.temp);
      delete item.temp;
    },
    doCancelFoodTech(item) {
      this.$set(item, 'editing', false);
      this.$delete(item, 'temp');
    },
    updateFoodTechFeeConfig(item) {
      const configData = {
        fee_type: item.fee_type,
        percent_fee: item.percent_fee,
        flat_fee: item.flat_fee,
        applied_on: item.applied_on,
        // is_apply_fee_enabled: item.is_apply_fee_enabled
      }
      this.$store.dispatch(`feeManagement/${UPDATE_FOOD_TECH_FEE_CONFIG_RECORD_IN_STORE}`, {
        configData: configData,
        paymentChannel: this.selectedPSP.key
      })
      .then((response) => {
        if(response.status_code == "2000") {
          this.$notify({
            type: 'success',
            text: response.message,
            closeOnClick: true,
          });
        } else {
          this.$notify({
            type: 'error',
            text: response.message,
            closeOnClick: true,
          });
        }
        // if(isFetchData) {
          this.fetchRecords();
        // }
      }).catch((error) => {
        this.$notify({
          type: 'error',
          text: error.message,
          closeOnClick: true,
        });
      });
    },

    // applyFeeChangedFoodTech(value, key, index) {
    //   if (value != this.feeManagementData[key][index].is_apply_fee_enabled) {
    //     this.feeManagementData[key][index].is_apply_fee_enabled = value;
    //     this.updateFoodTechFeeConfig(this.feeManagementData[key][index]);
    //   }
    // },

    removeAmountRule(index) {
      this.foodTechForm.rules.amount_rules.splice(index, 1);
      if(this.foodTechForm.rules.amount_rules.length === 0) {
        this.amountRules = false;
        this.amountRuleError = "";
      }
    },
    bankCodeChanged(value) {
      this.form.bank_name = value.bank_name;
    },
    saveOnStop() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.updateToleranceLevel, 700);
    },
    networkOptionSelected(value) {
      if(value.text === 'Rest of all') {
        while(this.form.network_id.length > 0) {
          this.form.network_id.pop();
        }
      } else {
        if(this.form.network_id[0].text === 'Rest of all') {
          this.form.network_id.shift();
        }
      }
    },
    currencySelected(value) {
      if(value === 'Rest of all') {
        while(this.form.currencies.length > 0) {
          this.form.currencies.pop();
        }
      } else {
        if(this.form.currencies[0] === 'Rest of all') {
          this.form.currencies.shift();
        }
      }
    },
    currencySelectedFoodtech(value) {
      if(value === 'Rest of all') {
        while(this.foodTechForm.currencies.length > 0) {
          this.foodTechForm.currencies.pop();
        }
      } else {
        if(this.foodTechForm.currencies[0] === 'Rest of all') {
          this.foodTechForm.currencies.shift();
        }
      }
    }
    // searchByChanged() {
    //   clearTimeout(this.timeout);
    //   this.timeout = setTimeout(this.fetchRecords, 700);
    // }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <div>
    <div class="menu-content">
      <p class="section-title">Fee Configuration</p>
      <p class="section-text">Select a data source and customise fee settings to accurately reflect transaction costs and enhance reconciliation accuracy.</p>

      <div style="background: #DCDAD5; height: 1px; margin-top: 25px; margin-bottom: 25px;"></div>

      <div class="d-flex">
        <div style="width: 300px;">
          <label class="mb-3" for="data_source">Data Source</label>
          <multiselect
            name="data_source"
            v-model="selectedPSP"
            :options="paymentChannelList"
            @input="pspChanged"
            placeholder="Select Data Source"
            track-by="key"
            label="name"
            :searchable="false"
          ></multiselect>
        </div>

        <div class="mx-4" style="background: rgba(220, 218, 213, 0.30); width: 2px;"></div>

        <div class="d-flex flex-column justify-content-between">
          <label for="fee_tolerance_level">Fee Tolerance Level</label>
          <div class="align-items-center d-flex">
            0
            <input v-model.number="tolerance_level" @change="updateToleranceLevel" type="range" step="0.0001" min="0" max="10" class="mx-2" :style="`background-size: ${tolerance_level * 10}% 100%;`" v-b-tooltip.hover.top="{ customClass: 'tooltip-white' }" top :title="tolerance_level" />
            10
            <b-input v-model.number="tolerance_level" @input="saveOnStop" @mousewheel.prevent min="0" max="10" type="number" class="tolerance-level-input ml-3" style="height: 36px;"></b-input>
          </div>
        </div>

        <b-btn
          variant="primary"
          v-b-modal.feeManagementModal
          class="mt-auto ml-auto border-0 px-3"
          style="font-weight: 400; height: 44px; line-height: 20px; border-radius: 12px;"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="mr-2">
            <path d="M10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.66602 10.7329V9.26621C1.66602 8.39954 2.37435 7.68287 3.24935 7.68287C4.75768 7.68287 5.37435 6.61621 4.61602 5.30787C4.18268 4.55787 4.44102 3.58287 5.19935 3.14954L6.64102 2.32454C7.29935 1.93287 8.14935 2.16621 8.54102 2.82454L8.63268 2.98287C9.38268 4.29121 10.616 4.29121 11.3743 2.98287L11.466 2.82454C11.8577 2.16621 12.7077 1.93287 13.366 2.32454L14.8077 3.14954C15.566 3.58287 15.8243 4.55787 15.391 5.30787C14.6327 6.61621 15.2494 7.68287 16.7577 7.68287C17.6244 7.68287 18.341 8.39121 18.341 9.26621V10.7329C18.341 11.5995 17.6327 12.3162 16.7577 12.3162C15.2494 12.3162 14.6327 13.3829 15.391 14.6912C15.8243 15.4495 15.566 16.4162 14.8077 16.8495L13.366 17.6745C12.7077 18.0662 11.8577 17.8329 11.466 17.1745L11.3743 17.0162C10.6243 15.7079 9.39102 15.7079 8.63268 17.0162L8.54102 17.1745C8.14935 17.8329 7.29935 18.0662 6.64102 17.6745L5.19935 16.8495C4.8361 16.6404 4.57067 16.2957 4.4613 15.891C4.35194 15.4864 4.40758 15.0549 4.61602 14.6912C5.37435 13.3829 4.75768 12.3162 3.24935 12.3162C2.37435 12.3162 1.66602 11.5995 1.66602 10.7329Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>{{ $t("views.fee_management.configure_fee") }}
        </b-btn>
      </div>
    </div>

    <div class="mb-3">
      <button type="button" @click="openAddEmailModal" class="btn btn-nav btn-sm"><img src="@/assets/images/tick.png" class="mr-1" v-if="reconEmailFromStore" alt height="12" />{{ $t("views.fee_management.email") }}</button>
    </div>

    <!-- <search-by class="mb-3" :options="searchByOptions" v-model="searchBy" @search-by-clicked="searchByChanged" /> -->

    <!-- <b-table-simple
      v-if="selectedPSP && (selectedPSP.channel_type === 'FOODTECH' || selectedPSP.channel_type === 'MARKETPLACE')"
      hover
      small
      fixed
      caption-top
      responsive="sm"
      class="table-custom"
      sticky-header="500vh"
    >
      <b-thead>
        <b-tr>
          <b-th>Fee Type</b-th>
          <b-th>Status</b-th>
          <b-th>Percent Fee</b-th>
          <b-th>Flat Fee</b-th>
          <b-th>Amount Rules</b-th>
          <b-th>Applied On</b-th>
          <b-th>Action</b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-for="(items, key) in feeManagementData" :key="key">
        <b-tr v-for="(element, index) in items" :key="index">
          <b-th :rowspan="items.length" v-if="index === 0" style="vertical-align: middle;">
            {{ key }}
          </b-th>
          <b-td>
            <switch-input @input="applyFeeChangedFoodTech($event, key, index)" :value="element.is_apply_fee_enabled" />
          </b-td>
          <b-td v-if="!element.editing">{{ element.percent_fee }} %</b-td>
          <b-td v-else>
            <b-input
              v-model.number="element.temp['percent_fee']"
              size="sm"
              @keydown.enter="doSaveFoodTech(element)"
              @input="inputHandler(element, 'percent_fee')"
              :state="element.validity['percent_fee']"
              :required="true"
              type="text"
            ></b-input>
          </b-td>
          <b-td v-if="!element.editing">{{ element.flat_fee }}</b-td>
          <b-td v-else>
            <b-input
              v-model.number="element.temp['flat_fee']"
              size="sm"
              @keydown.enter="doSaveFoodTech(element)"
              @input="inputHandler(element, 'flat_fee')"
              :state="element.validity['flat_fee']"
              :required="true"
              type="text"
            ></b-input>
          </b-td>
          <b-td>
            <img @click="showFeeRules(element)" src="@/assets/images/threedot.png" style="width: 15px; cursor: pointer;" alt />
          </b-td>
          <b-td>{{ element.applied_on_name }}</b-td>
          <b-td>
            <b-btn @click="doEditFoodTech(element)" v-if="!element.editing" size="sm" class="mr-2" variant="white" style="height: 36px; width: 36px; border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30);">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g opacity="0.3">
                  <path d="M8.83958 2.39982L3.36624 8.19315C3.15958 8.41315 2.95958 8.84649 2.91958 9.14649L2.67291 11.3065C2.58624 12.0865 3.14624 12.6198 3.91958 12.4865L6.06624 12.1198C6.36624 12.0665 6.78624 11.8465 6.99291 11.6198L12.4662 5.82649C13.4129 4.82649 13.8396 3.68649 12.3662 2.29315C10.8996 0.913152 9.78624 1.39982 8.83958 2.39982Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.92578 3.36621C8.21245 5.20621 9.70578 6.61288 11.5591 6.79954" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 14.667H14" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>
            </b-btn>
            <b-dropdown v-if="!element.editing" size="sm" variant="white bg-transparent" style="height: 36px; width: 36px; border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30);" dropleft>
              <template v-slot:button-content>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M7.5 14.25C7.5 15.075 8.175 15.75 9 15.75C9.825 15.75 10.5 15.075 10.5 14.25C10.5 13.425 9.825 12.75 9 12.75C8.175 12.75 7.5 13.425 7.5 14.25ZM7.5 3.75C7.5 4.575 8.175 5.25 9 5.25C9.825 5.25 10.5 4.575 10.5 3.75C10.5 2.925 9.825 2.25 9 2.25C8.175 2.25 7.5 2.925 7.5 3.75ZM7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5C8.175 7.5 7.5 8.175 7.5 9Z" stroke="black" stroke-opacity="0.3"/>
                </svg>
              </template>
              <b-dropdown-item-button @click="doDelete(element)">{{ $t("button.delete") }}</b-dropdown-item-button>
            </b-dropdown>
            <b-btn @click="doSaveFoodTech(element)" size="sm" class="mr-2" v-if="element.editing" variant="primary">
              Save
            </b-btn>
            <b-btn @click="doCancelFoodTech(element)" size="sm" v-if="element.editing" variant="light">
              Cancel
            </b-btn>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tbody v-if="Object.keys(feeManagementData).length === 0">
        <tr role="row" class="b-table-empty-row"><td colspan="12" role="cell" class=""><div role="alert" aria-live="polite"><div class="text-center my-2">{{ $t('common.no-records') }}</div></div></td></tr>
      </b-tbody>
    </b-table-simple> -->
    
    <b-table
      class="table-custom"
      :items="feeManagementData"
      :fields="fields"
      responsive="sm"
      :small="true"
      sticky-header="50vh"
      show-empty
      :empty-text="$t('common.no-records')"
      :busy="isFetching"
      hover
    >

      <template #table-busy>
        <div class="text-center">
          <b-spinner class="align-middle" :small="true"></b-spinner>
        </div>
      </template>

      <template v-slot:head()="data">
        {{ $t(data.label) }}
      </template>

      <template v-slot:cell(percent_fee)="{item, value, index, field}">
        <span v-if="!item.editing">
          {{ value }} %
        </span>
        <b-input
          v-else
          v-model.number="item.temp['percent_fee']"
          size="sm"
          @keydown.enter="doSave(item)"
          @input="inputHandler(item, field.key)"
          :state="feeManagementData[index].validity[field.key]"
          :required="true"
          type="number"
          step="0.01"
          min="0.00"
          max="100.00"
          @mousewheel.prevent
        ></b-input>
      </template>

      <template v-slot:cell(charge_key)="{value}">
        {{ chargeKeys[value] }}
      </template>

      <template v-slot:cell(charge_type)="{value}">
        {{ chargeTypes[value] }}
      </template>

      <template v-slot:cell(minimum_fee)="{item, value, index, field}">
        <span v-if="!item.editing">
          {{ value }}
        </span>
        <b-input
          v-else
          type="number"
          v-model.number="item.temp['minimum_fee']"
          size="sm"
          @keydown.enter.exact="doSave(item)"
          @input="inputHandler(item, field.key)"
          :state="feeManagementData[index].validity[field.key]"
          :required="false"
          @mousewheel.prevent
        ></b-input>
      </template>

      <template v-slot:cell(flat_fee)="{item, value, index, field}">
        <span v-if="!item.editing">
          {{ value }}
        </span>
        <b-input
          v-else
          type="number"
          v-model.number="item.temp['flat_fee']"
          size="sm"
          @keydown.enter.exact="doSave(item)"
          @input="inputHandler(item, field.key)"
          :state="feeManagementData[index].validity[field.key]"
          :required="true"
          @mousewheel.prevent
        ></b-input>
      </template>

      <!-- <template v-slot:cell(settlement_days)="{item, value, index, field}">
        <span v-if="!item.editing">
          {{ value }}
        </span>
        <b-select
          v-else
          v-model="item.temp['settlement_days']"
          :options="daysList"
          size="sm"
          @keydown.enter.exact="doSave(item)"
          @input="inputHandler(item, field.key)"
          :state="feeManagementData[index].validity[field.key]"
          :required="true"
          :min="0"
          :max="30"
        ></b-select>
      </template> -->

      <template v-slot:cell(percent_tax)="{item, value, index, field}">
        <span v-if="!item.editing">
          {{ value }} %
        </span>
        <b-input
          v-else
          v-model.number="item.temp['percent_tax']"
          size="sm"
          @keydown.enter.exact="doSave(item)"
          @input="inputHandler(item, field.key)"
          :state="feeManagementData[index].validity[field.key]"
          :required="true"
          type="number"
          step="0.01"
          min="0.00"
          max="100.00"
          @mousewheel.prevent
        ></b-input>
      </template>

      <template v-slot:cell(is_enabled)="{item, value}">
        <switch-input @input="applyFeeChanged($event, item)" :value="value" />
      </template>

      <template v-slot:cell(rules)="{ item }">
        <img @click="showFeeRules(item)" src="@/assets/images/threedot.png" style="width: 15px; cursor: pointer;" alt />
      </template>

      <!-- <template v-slot:cell(is_commercial)="row">
        <img src="@/assets/images/check-circle.svg" v-if="row.value">
        <img src="@/assets/images/x-circle.svg" v-else>
      </template> -->

      <template v-slot:cell(show_details)="{ item }">
        <b-btn v-if="!item.editing" @click="doEdit(item)" size="sm" class="mr-2" variant="white" style="height: 36px; width: 36px; border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30);">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g opacity="0.3">
              <path d="M8.83958 2.39982L3.36624 8.19315C3.15958 8.41315 2.95958 8.84649 2.91958 9.14649L2.67291 11.3065C2.58624 12.0865 3.14624 12.6198 3.91958 12.4865L6.06624 12.1198C6.36624 12.0665 6.78624 11.8465 6.99291 11.6198L12.4662 5.82649C13.4129 4.82649 13.8396 3.68649 12.3662 2.29315C10.8996 0.913152 9.78624 1.39982 8.83958 2.39982Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.92578 3.36621C8.21245 5.20621 9.70578 6.61288 11.5591 6.79954" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 14.667H14" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
        </b-btn>
        <!-- <b-dropdown v-if="!item.editing" size="sm" variant="white bg-transparent"  style="height: 36px; width: 36px; border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30);" dropleft>
          <template v-slot:button-content>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M7.5 14.25C7.5 15.075 8.175 15.75 9 15.75C9.825 15.75 10.5 15.075 10.5 14.25C10.5 13.425 9.825 12.75 9 12.75C8.175 12.75 7.5 13.425 7.5 14.25ZM7.5 3.75C7.5 4.575 8.175 5.25 9 5.25C9.825 5.25 10.5 4.575 10.5 3.75C10.5 2.925 9.825 2.25 9 2.25C8.175 2.25 7.5 2.925 7.5 3.75ZM7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5C8.175 7.5 7.5 8.175 7.5 9Z" stroke="black" stroke-opacity="0.3"/>
            </svg>
          </template>
          <b-dropdown-item-button @click="doDelete(item)">{{ $t("button.delete") }}</b-dropdown-item-button>
        </b-dropdown> -->
        <b-btn v-if="item.editing" @click="doSave(item)" size="sm" class="mr-2" variant="primary" :disabled="!item.valid">
          {{ $t("button.save") }}
        </b-btn>
        <b-btn v-if="item.editing" @click="doCancel(item)" size="sm" variant="light">
          {{ $t("button.cancel") }}
        </b-btn>
      </template>
    </b-table>

    <div class="d-flex justify-content-between">
      <per-page
        v-model="perPage"
        :currentPage="currentPage"
        :totalRows="rows"
        :tableRowsCount="feeManagementData.length"
        @input="fetchRecords"
      />
      <b-pagination
        class="my-auto"
        v-model="currentPage"
        :current-page="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        @input="fetchRecords"
      ></b-pagination>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-modal
          v-if="selectedPSP && (selectedPSP.channel_type === 'FOODTECH' || selectedPSP.channel_type === 'MARKETPLACE')"
          id="feeManagementModal"
          @show="resetFoodTechModal"
          @hidden="resetFoodTechModal"
          @ok="handleOkFoodTech"
          :ok-title="$t('button.save')"
          :cancel-title="$t('button.cancel')"
          content-class="custom-modal"
          size="lg"
          hide-header
          footer-class="custom-footer"
          ok-variant="chai"
          cancel-variant="cancel"
          no-close-on-backdrop
        >
          <div style="padding-right: 12px;">
            <div class="d-flex justify-content-between">
              <div>
                <h5 style="color: #000; font-size: 18px; font-style: normal; font-weight: 400; line-height: 24px; margin-bottom: 4px;">Configure Fee For <span class="text-chai">{{ selectedPSP ? selectedPSP.name : '' }}</span></h5>
                <p class="section-text" style="line-height: 16px;">These fee settings will be applied to the selected data source.</p>
              </div>

              <b-btn class="bg-white border-0 p-0 mb-auto" @click="$bvModal.hide('feeManagementModal')">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <g opacity="0.5" clip-path="url(#clip0_546_30488)">
                    <path d="M2.83363 14.5006L14.5009 2.83336M14.5009 14.5006L2.83363 2.83336" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_546_30488">
                      <rect width="18" height="18" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </b-btn>
            </div>

            <hr style="background: rgba(220, 218, 213, 0.30); border: 0; height: 1px; margin: 24px 0;">
          </div>

          <form ref="form" @submit.stop.prevent="handleSubmitFoodTech" class="row mx-auto">
            <b-form-group
              label="Fee Type"
              label-for="fee-type-input"
              :state="validateFoodTechState('fee_type')"
              invalid-feedback="Fee Type is required"
              class="col-md-6 pl-0"
            >
              <multiselect
                v-if="selectedPSP && selectedPSP.channel_type === 'FOODTECH'"
                id="fee-type-input"
                v-model="$v.foodTechForm.fee_type.$model"
                :options="paymentMethodsOfPaymentChannel.foodtech_config"
                @input="() => foodTechForm.applied_on = null"
                placeholder="Select Fee Type"
                track-by="key"
                label="display_name"
                :class="$v.foodTechForm.fee_type.$error ? 'is-invalid' : (isSubmited ? 'is-valid' : '')"
                :searchable="false"
              ></multiselect>

              <multiselect
                v-else-if="selectedPSP && selectedPSP.channel_type === 'MARKETPLACE'"
                id="fee-type-input"
                v-model="$v.foodTechForm.fee_type.$model"
                :options="paymentMethodsOfPaymentChannel.marketplace_config"
                @input="() => foodTechForm.applied_on = null"
                placeholder="Select Fee Type"
                track-by="key"
                label="display_name"
                :class="$v.foodTechForm.fee_type.$error ? 'is-invalid' : (isSubmited ? 'is-valid' : '')"
                :searchable="false"
              ></multiselect>
            </b-form-group>

            <b-form-group
              label="Applied On"
              label-for="applied-on-input"
              :state="validateFoodTechState('applied_on')"
              invalid-feedback="Applied On is required"
              class="col-md-6 pl-0"
            >
              <multiselect
                id="applied-on-input"
                v-model="$v.foodTechForm.applied_on.$model"
                :options="appliedOnList"
                placeholder="Select Applied On"
                track-by="key"
                label="display_name"
                :class="$v.foodTechForm.applied_on.$error ? 'is-invalid' : (isSubmited ? 'is-valid' : '')"
                :searchable="false"
              ></multiselect>
            </b-form-group>

            <b-form-group
              :label="$t('views.fee_management.table.currency')"
              label-for="currency-input"
              :invalid-feedback="$t('views.fee_management.validation.currency')"
              class="col-md-6 pl-0"
              >
              <multiselect
                name="currency-input"
                v-model="$v.foodTechForm.currencies.$model"
                :options="currencyOptions"
                placeholder="Select Currencies"
                :state="validateFoodTechState('currencies')"
                @select="currencySelectedFoodtech"
                multiple
                :searchable="false"
              ></multiselect>
            </b-form-group>

            <b-form-group
              label="Percent Fee"
              label-for="percent-fee-input"
              invalid-feedback="Percent Fee is required and must be between 0 to 100"
              class="col-md-3 pl-0"
            >
              <b-form-input
                id="percent-fee-input"
                type="number"
                v-model.number="$v.foodTechForm.percent_fee.$model"
                :state="validateFoodTechState('percent_fee')"
                @mousewheel.prevent
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Flat Fee"
              label-for="flat-fee-input"
              invalid-feedback="flat Fee is required"
              class="col-md-3 pl-0"
            >
              <b-form-input
                id="flat-fee-input"
                type="number"
                v-model.number="$v.foodTechForm.flat_fee.$model"
                :state="validateFoodTechState('flat_fee')"
                @mousewheel.prevent
              ></b-form-input>
            </b-form-group>

            <!-- <label class="col-md-4 my-auto d-flex justify-content-end pl-0">
              {{ $t('views.fee_management.apply_fee') }}
              <switch-input class="ml-2" v-model="foodTechForm.is_apply_fee_enabled" :value="foodTechForm.is_apply_fee_enabled" />
            </label> -->

            <div>
              <b-btn @click="openAmountRules" size="sm" variant="white" class="text-chai px-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M5 10H15M10 15V5" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ $t('views.fee_management.add_amount_rule') }}
              </b-btn>
              <b-btn v-if="amountRules" size="sm" variant="white" class="ml-1" @click="closeAmountRules">
                Cancel
              </b-btn>
            </div>

            <div v-if="amountRules" class="mt-4 col-md-12 pl-0">
              <b-row class="table-heading-custom m-0">
                <b-col md="4"><label class="m-0">Minimum Amount</label></b-col>
                <b-col md="4"><label class="m-0">Maximum Amount</label></b-col>
                <b-col md="3"><label class="m-0">{{$t('views.fee_management.table.percent_fee')}}</label></b-col>
                <b-col md="1"></b-col>
              </b-row>
              <b-row style="border-bottom: 1px solid #DCDAD5;" class="m-0" v-for="(rule, index) in foodTechForm.rules.amount_rules" :key="index">
                  <b-col md="4"><b-form-input type="number" v-model.number="foodTechForm.rules.amount_rules[index].min_amount" @mousewheel.prevent placeholder="0" class="h-100 border-0"></b-form-input></b-col>
                  <b-col md="4"><b-form-input type="number" v-model.number="foodTechForm.rules.amount_rules[index].max_amount" @mousewheel.prevent placeholder="0" class="h-100 border-0"></b-form-input></b-col>
                  <b-col md="3"><b-form-input type="number" v-model.number="foodTechForm.rules.amount_rules[index].percent_fee" @mousewheel.prevent placeholder="0" class="h-100 border-0"></b-form-input></b-col>
                  <b-col md="1" class="my-2 py-1">
                    <div class="d-flex h-100">
                      <b-btn variant="white" @click="removeAmountRule(index)" size="sm" class="p-0 my-auto delete-btn" style="border-radius: 4px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M7.24264 16.2426L15.7279 7.75736M15.7279 16.2426L7.24264 7.75736" stroke="#E36464" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </b-btn>
                    </div>
                  </b-col>
              </b-row>
              <p class="text-danger mt-2 mb-0" style="font-size: 80%;" v-if="amountRuleError">{{ amountRuleError }}</p>
              <b-btn variant="white" class="mt-4 px-0" size="sm" @click="addMoreRule">+ Add Rule</b-btn>
            </div>
          </form>
        </b-modal>

        <b-modal
          v-else
          id="feeManagementModal"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
          :ok-title="$t('button.save')"
          :cancel-title="$t('button.cancel')"
          content-class="custom-modal"
          size="lg"
          hide-header
          footer-class="custom-footer"
          ok-variant="chai"
          cancel-variant="cancel"
          no-close-on-backdrop
        >
          <div style="padding-right: 12px;">
            <div class="d-flex justify-content-between">
              <div>
                <h5 style="color: #000; font-size: 18px; font-style: normal; font-weight: 400; line-height: 24px; margin-bottom: 4px;">Configure Fee For <span class="text-chai">{{ selectedPSP ? selectedPSP.name : '' }}</span></h5>
                <p class="section-text" style="line-height: 16px;">These fee settings will be applied to the selected data source.</p>
              </div>
    
              <b-btn class="bg-white border-0 p-0 mb-auto" @click="$bvModal.hide('feeManagementModal')">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <g opacity="0.5" clip-path="url(#clip0_546_30488)">
                    <path d="M2.83363 14.5006L14.5009 2.83336M14.5009 14.5006L2.83363 2.83336" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_546_30488">
                      <rect width="18" height="18" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </b-btn>
            </div>
  
            <hr style="background: rgba(220, 218, 213, 0.30); border: 0; height: 1px; margin: 24px 0;">
          </div>
          
          <form ref="form" @submit.stop.prevent="handleSubmit" class="row mx-auto">
            <b-form-group
              :label="$t('views.fee_management.table.pm_name')"
              label-for="payment-method-input"
              :invalid-feedback="$t('views.fee_management.validation.pm_name')"
              :state="validateState('paymentMethod')"
              class="col-md-6 pl-0"
            >
              <multiselect
                name="payment-method-input"
                v-model="$v.form.paymentMethod.$model"
                :options="paymentMethodListOfPaymentChannel"
                @input="paymentMethodChanged"
                placeholder="Select Payment Method"
                track-by="value"
                label="text"
                :searchable="false"
              ></multiselect>
            </b-form-group>

            <b-form-group
              :label="$t('views.fee_management.table.currency')"
              label-for="currency-input"
              :invalid-feedback="$t('views.fee_management.validation.currency')"
              class="col-md-6 pl-0"
              >
              <multiselect
                name="currency-input"
                v-model="$v.form.currencies.$model"
                :options="currencyOptions"
                placeholder="Select Currencies"
                :state="validateState('currencies')"
                @select="currencySelected"
                multiple
                :searchable="false"
              ></multiselect>
            </b-form-group>

            <b-form-group
              v-if="showInstallment"
              label="Bank"
              label-for="bank-code-input"
              invalid-feedback="Bank name is required"
              class="col-md-6 pl-0"
            >
              <multiselect
                name="bank-code-input"
                v-model="form.bank_code"
                :options="paymentMethodsOfPaymentChannel.installment_details"
                placeholder="Select Bank Name"
                track-by="bank_code"
                label="bank_name"
                :state="validateState('bank_code')"
                @input="bankCodeChanged"
                :searchable="false"
              ></multiselect>
            </b-form-group>

            <b-form-group
              v-if="showInstallment"
              label="Installment"
              label-for="installment-input"
              invalid-feedback="Installment is required"
              class="col-md-6 pl-0"
            >
              <multiselect
                name="installment-term-input"
                v-model="$v.form.installment_term.$model"
                :options="form.bank_code.terms || []"
                placeholder="Select Installment Term"
                :state="validateState('installment_term')"
                :searchable="false"
              ></multiselect>
            </b-form-group>

            <b-form-group
              v-if="networkOptions.length > 1"
              label="Network"
              label-for="network-input"
              invalid-feedback="network is required"
              class="col-md-12 pl-0"
            >
              <multiselect
                id="network-input"
                v-model="$v.form.network_id.$model"
                :options="networkOptions"
                :state="validateState('network_id')"
                placeholder="Select network"
                track-by="value"
                label="text"
                @select="networkOptionSelected"
                :class="$v.form.network_id.$error ? 'is-invalid' : (isSubmited ? 'is-valid' : '')"
                :searchable="false"
                :multiple="true"
                :taggable="true"
              ></multiselect>
            </b-form-group>

            <b-form-group
              :label="$t('views.fee_management.table.percent_fee')"
              label-for="percent-fee-input"
              :invalid-feedback="$t('views.fee_management.validation.percent_fee')"
              class="col-md-4 pl-0"
            >
              <b-form-input
                id="percent-fee-input"
                type="number"
                v-model.number="$v.form.percentFee.$model"
                :state="validateState('percentFee')"
                step="0.01"
                min="0.00"
                max="100.00"
                @mousewheel.prevent
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('views.fee_management.table.minimum_fee')"
              label-for="minimum-fee-input"
              :invalid-feedback="$t('views.fee_management.validation.minimum_fee')"
              class="col-md-4 pl-0"
            >
              <b-form-input
                id="minimum-fee-input"
                type="number"
                v-model.number="$v.form.minimumFee.$model"
                :state="validateState('minimumFee')"
                @mousewheel.prevent
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('views.fee_management.table.flat_fee')"
              label-for="flat-fee-input"
              :invalid-feedback="$t('views.fee_management.validation.flat_fee')"
              class="col-md-4 pl-0"
            >
              <b-form-input
                id="flat-fee-input"
                type="number"
                v-model.number="$v.form.flatFee.$model"
                :state="validateState('flatFee')"
                @mousewheel.prevent
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('views.fee_management.table.tax_percentage')"
              label-for="tax-input"
              :invalid-feedback="$t('views.fee_management.validation.tax_percentage')"
              class="col-md-4 pl-0"
            >
              <b-form-input
                id="tax-input"
                type="number"
                v-model.number="$v.form.tax.$model"
                :state="validateState('tax')"
                @mousewheel.prevent
              ></b-form-input>
            </b-form-group>

            <!-- <b-form-group
              :label="$t('views.fee_management.table.settlement_days')"
              label-for="settlement-cycle-input"
              :invalid-feedback="$t('views.fee_management.validation.settlement_days')"
              class="col-md-4 pl-0"
            >
              <multiselect
                name="settlement-cycle-input"
                v-model="$v.form.settlementCycleDays.$model"
                :options="daysList"
                placeholder="Select Settlement Cycle"
                :state="validateState('settlementCycleDays')"
                :searchable="false"
              ></multiselect>
            </b-form-group> -->

            <!-- <div class="col-md-4 d-flex flex-column pl-0">
              <label class="my-auto d-flex justify-content-end">
                Is Commercial
                <switch-input class="ml-2" v-model="form.is_commercial" :value="form.is_commercial" />
              </label>
              <label class="my-auto d-flex justify-content-end">
                {{ $t('views.fee_management.apply_fee') }}
                <switch-input class="ml-2" v-model="form.status" :value="form.status" />
              </label>
            </div> -->


            <!-- <div class="col-md-6">
              <b-form-checkbox
                id="status-input"
                v-model="$v.form.status.$model"
                :value="true"
                :unchecked-value="false"
              >
                {{ $t('views.fee_management.apply_fee') }}
              </b-form-checkbox>
            </div> -->

            <div class="col-md-12">
              <b-btn @click="openAmountRules" size="sm" variant="white" class="text-chai px-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M5 10H15M10 15V5" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ $t('views.fee_management.add_amount_rule') }}
              </b-btn>
              <b-btn v-if="amountRules" size="sm" variant="white" class="ml-1" @click="closeAmountRules">
                Cancel
              </b-btn>
            </div>

            <div v-if="amountRules" class="mt-4 col-md-12 mx-auto pl-0">
              <b-row class="table-heading-custom m-0">
                <b-col md="3"></b-col>
                <b-col md="3">Greater Than</b-col>
                <b-col md="3">Less Than</b-col>
                <b-col md="3">Equal To</b-col>
              </b-row>

              <b-row class="m-0" style="border-bottom: 1px solid #DCDAD5;">
                <b-col md="3" style="border-right: 1px solid #DCDAD5;">
                  <div class="my-2 py-1 font-14px">
                    {{ $t('common.amount') }}
                  </div>
                </b-col>
                <b-col md="3" v-for="(rule, index) in form.rules.amount_rules" :key="`amount_${rule.operator}`">
                  <b-form-input type="number" v-model.number="form.rules.amount_rules[index].amount" class="h-100 border-0" @mousewheel.prevent placeholder="0"></b-form-input>
                </b-col>
              </b-row>

              <b-row class="m-0" style="border-bottom: 1px solid #DCDAD5;">
                <b-col md="3" style="border-right: 1px solid #DCDAD5;">
                  <div class="my-2 py-1 font-14px">
                    {{ $t('views.fee_management.table.percent_fee') }}
                  </div>
                </b-col>
                <b-col md="3" v-for="(rule, index) in form.rules.amount_rules" :key="`fee_${rule.operator}`">
                  <b-form-input type="number" v-model.number="form.rules.amount_rules[index].percent_fee" class="h-100 border-0" @mousewheel.prevent placeholder="0"></b-form-input>
                </b-col>
              </b-row>
            </div>
          </form>
        </b-modal>
             
        <!-- Email Configuration -->
        <!-- Right Sidebar -->
        <div class="right-bar"
        :class="{
            'active': showAddEmailModal
          }">
          <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt />
          <div data-simplebar class="h-100">
            <div class="rightbar-title pb-3">
              <div class="right-top-section">
                <img src="@/assets/images/update_config_page.png" class="right-top-bg-image" alt />
                <div class="card-body-custom">
                  <i class="mdi mdi-close noti-icon float-right cursor-pointer" @click="hideAddEmailModal"></i>
                  <div class="media-body media-body-custom d-inline-block">
                    <p class="d-inline-block mb-0 mt-4 font-size-15">{{ $t('views.fee_management.email_config') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="p-3"> -->
              <form id="email-modal" class="p-3" action="#" @submit.prevent="updatePSPEmailDetails">
                <div class="form-group">
                  <label style="" for="recon_email">{{ $t('views.fee_management.recon_email') }}</label>
                  <i
                    class="ri-file-copy-line ml-2 align-middle cursor-pointer copy-icon"
                    v-clipboard:copy="reconEmailFromStore"
                    v-clipboard:success="onCopyReconEmail"
                    v-b-tooltip.hover
                    :title="$t('common.copy')"
                  ></i>
                  <input
                    id="reconEmail"
                    type="text"
                    class="form-control"
                    name="reconEmail"
                    v-clipboard:copy="reconEmailFromStore"
                    :value="reconEmailFromStore"
                    readonly
                    style="padding-right: 35px;"
                  />
                </div>
                <div v-if="reconConfirmationCodeFromStore || reconConfirmationLinkFromStore">
                  <div class="form-group" v-if="reconConfirmationCodeFromStore">
                    <label style="" for="recon_email">{{ $t('Recon Confirmation Code') }}</label>
                    <i
                      class="ri-file-copy-line ml-2 align-middle cursor-pointer copy-icon"
                      v-clipboard:copy="reconConfirmationCodeFromStore"
                      v-clipboard:success="onCopyConfirmationCode"
                      v-b-tooltip.hover
                      :title="$t('common.copy')"
                    ></i>
                    <input
                      id="reconEmail"
                      type="text"
                      class="form-control"
                      name="reconEmail"
                      v-clipboard:copy="reconConfirmationCodeFromStore"
                      :value="reconConfirmationCodeFromStore"
                      readonly
                      style="padding-right: 35px;"
                    />
                  </div>
                  <b-link v-if="reconConfirmationLinkFromStore" :href="reconConfirmationLinkFromStore" target="_blank">Confirmation Link <i style="font-size: 15px;" class="mdi mdi-open-in-new"></i></b-link>
                </div>
                <div v-else class="mt-4" style="text-align: left; font: normal normal normal 15px/18px Karla; letter-spacing: 0px; color: #707070; opacity: 0.5;">{{ $t("Once autoforwarding has been added to the provided email address, we will display the verification code and link here") }}</div>
                <div class="mt-4">
                  <div style="text-align: left; font: normal normal normal 15px/18px Karla; letter-spacing: 0px; color: #707070; opacity: 0.5;">{{ $t("views.fee_management.set_rule_note") }}</div>
                </div>

                <div class="text-left" style="position:relative; margin-top:55%;">
                  <b-button class="btn-block" variant="light" @click="hideAddEmailModal">{{ $t("button.close") }}</b-button>
                </div>
              </form>
          </div>
          <!-- end slimscroll-menu-->
        </div>
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <div @click="hideAddEmailModal" class="rightbar-overlay"
        :class="{
            'active': showAddEmailModal
        }"></div>

        <!-- SFTP Configuration -->
        <div class="right-bar"
        :class="{
            'active': showSFTPModal
          }">
          <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt />
          <div data-simplebar class="h-100">
            <div class="rightbar-title pb-3">
              <div class="right-top-section">
                <img src="@/assets/images/update_config_page.png" class="right-top-bg-image" alt />
                <div class="card-body-custom">
                  <i class="mdi mdi-close noti-icon float-right cursor-pointer" @click="hideSFTPModal"></i>
                  <div class="media-body media-body-custom d-inline-block">
                    <p class="d-inline-block mb-0 mt-4 font-size-15">{{ $t("views.fee_management.sftp_config") }}</p>
                  </div>
                </div>
              </div>
            </div>
            <form id="sftp-modal" class="p-3" action="#" @submit.prevent="updateSFTPDetails">
              <div
                class="form-group"
              >
                <input
                  id="sftp_link"
                  v-model="feeConfig.sftp_link"
                  type="text"
                  class="form-control"
                  :placeholder="$t('views.fee_management.sftp_link_placeholder')"
                  name="sftp_link"
                  ref="sftpLink"
                />
              </div>

              <div
                class="form-group"
              >
                <input
                  id="sftp_username"
                  v-model="feeConfig.sftp_username"
                  type="text"
                  class="form-control"
                  :placeholder="$t('views.fee_management.sftp_username_placeholder')"
                  name="sftp_username"
                  autocomplete="off"
                />
              </div>

              <div
                class="form-group"
              >
                <input
                  id="sftp_password"
                  v-model="feeConfig.sftp_password"
                  type="password"
                  class="form-control"
                  :placeholder="$t('views.fee_management.sftp_password_placeholder')"
                  name="sftp_password"
                  autocomplete="off"
                />
              </div>

              <div class="text-left" style="position:relative; margin-top:55%;">
                <b-button class="btn-block" variant="light" @click="hideSFTPModal">{{ $t("button.cancel") }}</b-button>
                <button
                  type="submit"
                  class="btn btn-block btn-success dark-bg"
                  :disabled="!isValidSFTPConfiguration"
                >
                  {{ $t("button.save") }}
                </button>
              </div>
            </form>
          </div>
          <!-- end slimscroll-menu-->
        </div>

        <b-modal
          size="lg"
          id="create-validations-modal"
          class="trxn-details-modal"
          v-model="showFeeRulesModal"
          title="Fees Rules"
          title-class="text-dark font-18"
          @hide="showFeeRules"
          hide-footer
          centered 
        >
          <div class="col-md-12" v-if="feesRules.amount_rules && feesRules.amount_rules.length > 0">
            <b-row class="mt-2">
              <b-col><label class="m-0">{{ selectedPSP && (selectedPSP.channel_type === 'FOODTECH' || selectedPSP.channel_type === 'MARKETPLACE') ? 'Maximum Amount' : 'Operator' }}</label></b-col>
              <b-col><label class="m-0">{{ selectedPSP && (selectedPSP.channel_type === 'FOODTECH' || selectedPSP.channel_type === 'MARKETPLACE') ? 'Minimum Amount' : 'Amount' }}</label></b-col>
              <b-col><label class="m-0">Percent Fee</label></b-col>
            </b-row>
            <div v-for="(rule, index) in feesRules.amount_rules" :key="index" class="my-2">
              <b-input-group size="sm" v-if="selectedPSP && (selectedPSP.channel_type === 'FOODTECH' || selectedPSP.channel_type === 'MARKETPLACE')">
                <b-form-input type="text" disabled readonly :value="rule.max_amount" placeholder="Maximum Amount"></b-form-input>
                <b-form-input type="number" disabled readonly :value="rule.min_amount" placeholder="Minimum Amount"></b-form-input>
                <b-form-input type="number" disabled readonly :value="rule.percent_fee" placeholder="Percent Fee"></b-form-input>
              </b-input-group>
              <b-input-group size="sm" v-else>
                <b-form-input type="text" disabled readonly :value="feesParams[rule.operator]"></b-form-input>
                <b-form-input type="number" disabled readonly :value="rule.amount" placeholder="Amount"></b-form-input>
                <b-form-input type="number" disabled readonly :value="rule.percent_fee" placeholder="Percent Fee"></b-form-input>
              </b-input-group>
            </div>
          </div>
          <div v-else>
            <p class="text-center">Percent Fee Rule is not available.</p>
          </div>
        </b-modal>
        <!-- /Right-bar -->
        <!-- Right bar overlay-->
        <div @click="hideSFTPModal" class="rightbar-overlay"
        :class="{
            'active': showSFTPModal
        }"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-content {
  padding: 25px;
  display: block;
}
.section-title {
  line-height: 24px;
}
.section-text {
  line-height: 16px;
}

label {
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 20px;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 250px;
  height: 7px;
  background: #DCDAD5;
  border-radius: 5px;
  background-image: linear-gradient(#FC6B2D, #FC6B2D);
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #FFF;
  background: #FC6B2D;
  cursor: ew-resize;
}

input[type=range]::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

::v-deep .tooltip-inner {
  color: #000 !important;
  background-color: #FFF !important;
  border-radius: var(--8, 8px);
  border: 1px solid rgba(220, 218, 213, 0.30);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
::v-deep .arrow::before {
  display: none;
}
.tolerance-level-input {
  border-radius: 12px;
  border: 1px solid #DCDAD5;
  background: transparent;
  width: 82px;
}
::v-deep .switch {
  height: 20px;
  width: 36px;
  .slider {
    height: 20px;
    width: 36px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
}
::v-deep .custom-modal {
  .modal-body {
    padding: 32px 20px 32px 32px;
  }
  .custom-footer {
    border-radius: 0px 0px 16px 16px;
    background: #FFF;
    box-shadow: 0px -4px 10px 0px rgba(220, 218, 213, 0.30);
    padding: 23px 32px 25px;
    .btn {
      font-size: 14px;
      font-style: normal;
      font-weight: 350;
      line-height: 20px;
      padding: 8px 16px;
      height: 36px;
      border-radius: var(--8, 8px);
      border: none;
      margin: 0;
    }
    .btn-chai {
      color: #FC6B2D;
    }
    .btn-cancel {
      color: #000;
    }
    .btn:hover {
      background: rgba(220, 218, 213, 0.30);
    }
  }
  border-radius: 16px !important;
  background: #FFF !important;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15) !important;
}
::v-deep  .custom-multiselect .multiselect__tags{
  border: 0.5px solid #00246414 !important;
}
.custom-multiselect {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
}
::v-deep .custom-multiselect .multiselect__select:before{
  z-index: 1;
}
.custom-multiselect.is-invalid {
  border: 1px solid #ff3d60 !important;
  border-radius: 0.25rem;
}
.custom-multiselect.is-valid {
  border: 1px solid #1cbb8c !important;
  border-radius: 0.25rem;
}
.media-body-custom{
  font-size: 12px;
  position: relative;
}
.card-body-custom{
  padding:15px 20px;
  position: relative;
}
.dark-bg{
  background-color: #252B3A;
  border-color: #252B3A;
}
.right-top-section{
  /* border-bottom: 1px solid #D9D9D9; */
  background-color: #F7F7F7;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  position: relative;
  z-index: 1;
  height: 130px;
}
.right-bar{
  width: 25%;
  right: -25%;
  z-index: 1010;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay{
  z-index: 1009;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}
@media (max-width: 1300px) {
  .right-bar{
    width: 360px;
    right: -360px;
  }
}
@media (max-width: 400px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}

.btn-nav {
  border: 0.5px solid #8d8a8a;
  color: #8d8a8a;
}

.btn-nav::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  vertical-align: 0;
  margin-left: 10px;
}
.copy-icon{
  position: absolute;
  right: 27px;
  font-size: 18px;
  margin-top: 35px;
}
.form-control {
  font-size: 14px;
}
::v-deep label {
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 20px;
}
.table-heading-custom {
  border-radius: 12px;
  background: rgba(220, 218, 213, 0.30);
  font-size: 14px;
  font-weight: 350;
  line-height: 20px;
  padding: 12px;
}

::v-deep .modal .form-group .form-control {
  border-radius: 12px;
  border: 1px solid #DCDAD5;
  height: 44px;
}
.delete-btn:hover {
  background: rgba(220, 218, 213, 0.30);
}
</style>