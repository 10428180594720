
<script>
import {
  required,
  integer,
  minValue,
  requiredIf,
} from "vuelidate/lib/validators";

import {
  POST_FILE_MANAGEMENT_DATA,
  POST_FILE_CONFIG,
} from "@/state/actions.type";

export default {
  props: {
    showForm: Boolean,
    formType: String,
    paymentChannels: Array,
    selectedPaymentChannel: String,
    currentItem: Object,
  },

  validations: {
    form: {
      step1: {
        display_name: {
          required,
        },
        sheet_number: {
          required,
          integer,
          minValue: minValue(0),
        },
        offset: {
          required,
          integer,
          minValue: minValue(0),
        },
        file: {
          requiredIf: requiredIf(function () {
            return !this.currentItem;
          }),
        },
      },
      step2: {
        date: {
          required,
        },
        amount: {
          required,
        },
        txn_ref: {
          required,
        },
        status: {
          requiredIf: requiredIf(function () {
            return this.formType === "recon";
          }),
        },
        storeid: {},
        merchantref: {},
        paymentmethod: {},
        paymenttype: {},
        amount_refunded: {},
        payment_network: {}
      },
      step3: {
        date_format: {
          required,
        },
        payment_channel_key: {
          required,
        },
        refund_offset: {
          requiredIf: requiredIf(function () {
            return this.refundIncluded;
          }),
          integer,
          minValue: minValue(0),
        },
        refund_sheet_number: {
          requiredIf: requiredIf(function () {
            return this.refundIncluded;
          }),
          integer,
          minValue: minValue(-1),
        },
        currency_key: {
          required,
        },
        timezone_key: {
          required,
        },
      },
      step4: {
        date: {
          requiredIf: requiredIf(function () {
            return this.formType === "recon" && this.refundIncluded;
          }),
        },
        amount: {
          requiredIf: requiredIf(function () {
            return this.formType === "recon" && this.refundIncluded;
          }),
        },
        status: {
          requiredIf: requiredIf(function () {
            return this.formType === "recon" && this.refundIncluded;
          }),
        },
        txn_ref: {
          requiredIf: requiredIf(function () {
            return this.formType === "recon" && this.refundIncluded;
          }),
        },
        status_code_mapping: {
          success_status: {
            requiredIf: requiredIf(function () {
              return this.formType === "transaction" && this.form.step2.status;
            }),
          },
          failed_status: {},
          pending_status: {},
        },
        transaction_type_mapping: {
          forward: {
            requiredIf: requiredIf(function () {
              return this.formType === "transaction" && this.form.step2.paymenttype;
            }),
          },
          refund: {},
          partial_refund: {},
        },
      },
    },
  },

  data() {
    return {
      form: {
        step1: {
          display_name: "",
          sheet_number: 0,
          offset: 0,
          file: null,
        },
        step2: {
          date: "",
          amount: "",
          status: "",
          txn_ref: "",
          storeid: "",
          merchantref: "",
          paymentmethod: "",
          paymenttype: "",
          amount_refunded: "",
          payment_network: ""
        },
        step3: {
          date_format: "",
          payment_channel_key: null,
          refund_offset: 0,
          refund_sheet_number: 0,
          currency_key: "INR",
          timezone_key: "Global",
        },
        step4: {
          date: "",
          amount: "",
          status: "",
          txn_ref: "",
          status_code_mapping: {
            success_status: "",
            failed_status: "",
            pending_status: "",
          },
          payment_method_mapping: {
            credit_card: "",
            debit_card: "",
            net_banking: "",
            upi: "",
            wallet: "",
            cash: "",
            emi: "",
            pay_later: "",
          },
          transaction_type_mapping: {
            forward: "",
            refund: "",
            partial_refund: "",
          },
        },
        charge_params: {
          fee: "",
          tax: "",
          refund_fee: "",
          refund_tax: "",
        },
        all_headers: [],
      },
      processing: false,
      status_code_mapping: [
        { name: "Success", id: "success_status", optional: false },
        { name: "Failed", id: "failed_status", optional: true },
        { name: "Pending", id: "pending_status", optional: true },
      ],
      payment_method_mapping: [
        { payment_method: "Credit Card", id: "credit_card" },
        { payment_method: "Debit Card", id: "debit_card" },
        { payment_method: "Net Banking", id: "net_banking" },
        { payment_method: "UPI", id: "upi" },
        { payment_method: "Wallet", id: "wallet" },
        { payment_method: "Cash", id: "cash" },
        { payment_method: "EMI", id: "emi" },
        { payment_method: "Pay Later", id: "pay_later" },
      ],
      transaction_type_mapping: [
        { transaction_type: "Forward", id: "forward", optional: false },
        { transaction_type: "Refund", id: "refund", optional: true },
        { transaction_type: "Partial Refund", id: "partial_refund", optional: true }
      ],
      currencyToFlagMapping: {
        VND: require("@/assets/images/flags/vietnam1.png"),
        THB: require("@/assets/images/flags/thailand.jpeg"),
        SGD: require("@/assets/images/flags/singapore.png"),
        IDR: require("@/assets/images/flags/indonesia.png"),
        PHP: require("@/assets/images/flags/philippines.png"),
        MYR: require("@/assets/images/flags/malaysia.svg"),
        TWD: require("@/assets/images/flags/taiwan.png"),
        HKD: require("@/assets/images/flags/hong_kong.svg"),
        KHR: require("@/assets/images/flags/cambodia.svg"),
        WST: require("@/assets/images/flags/samoa.svg"),
        USD: require("@/assets/images/flags/united_states.svg"),
        INR: require("@/assets/images/flags/india.svg"),
        AUD: require("@/assets/images/flags/australia.svg"),
        KRW: require("@/assets/images/flags/korea.svg"),
        JPY: require("@/assets/images/flags/japan.svg"),
        EUR: require("@/assets/images/flags/europe.svg"),
        AED: require("@/assets/images/flags/uae.svg"),
      },
      currencies: [
        "VND",
        "THB",
        "SGD",
        "IDR",
        "PHP",
        "MYR",
        "TWD",
        "USD",
        "EUR",
        "JPY",
        "KRW",
        "AUD",
        "HKD",
        "KHR",
        "WST",
        "AED",
        "INR",
      ],
      countries: [
        "Global",
        "Hong Kong",
        "India",
        "Indonesia",
        "Japan",
        "Myanmar",
        "Philippines",
        "Singapore",
        "South Korea",
        "Taiwan",
        "Thailand",
        "Vietnam",
      ],
      countryToTimezoneoffset: {
        Global: 0,
        "Hong Kong": 8,
        India: 5.5,
        Indonesia: 7,
        Japan: 9,
        Myanmar: 6.5,
        Philippines: 8,
        Singapore: 8,
        "South Korea": 9,
        Taiwan: 8,
        Thailand: 7,
        Vietnam: 7,
      },
      fileHeaders: [],
      refundIncluded: false,
      formStep: 1,
      goToNext: true,
      isSubmited: false,
      modalFormTitle: "",
      columnsForSettlement: [
        {
          id: "date-column",
          label: "Date",
          value: "date",
          optional: false,
          description:
            "Please select the valid date column name from the file.",
        },
        {
          id: "amount-column",
          label: "Amount",
          value: "amount",
          optional: false,
          description:
            "Please select the valid amount column name from the file.",
        },
        {
          id: "status-column",
          label: "Status",
          value: "status",
          optional: false,
          description:
            "Please select the valid status column name from the file.",
        },
        {
          id: "reference-column",
          label: "Referance Id",
          value: "txn_ref",
          optional: false,
          description:
            "Please select the valid reference column name from the file.",
        },
      ],
      columnsForTransaction: [
        {
          id: "date-column",
          label: "Date",
          value: "date",
          description:
            "Please select the valid date column name from the file.",
          optional: false,
          mapping: false,
        },
        {
          id: "amount-column",
          label: "Amount",
          value: "amount",
          description:
            "Please select the valid amount column name from the file.",
          optional: false,
          mapping: false,
        },
        {
          id: "reference-column",
          label: "Referance Id",
          value: "txn_ref",
          description:
            "Please select the valid reference column name from the file.",
          optional: false,
          mapping: false,
        },
        {
          id: "store-id-column",
          label: "Store Id",
          value: "storeid",
          description: "Please select colunmn for restauraunt store id",
          optional: true,
          mapping: false,
        },
        {
          id: "merchant-reference-column",
          label: "Merchant's Reference Id",
          value: "merchantref",
          description:
            "Please select column which contains merchant's reference id.",
          optional: true,
          mapping: false,
        },
        {
          id: "status-column",
          label: "Status",
          value: "status",
          description:
            "Please select the valid status column name from the file.",
          optional: true,
          mapping: true,
        },
        {
          id: "payment-method-column",
          label: "Payment Method",
          value: "paymentmethod",
          description: "Please select column which contains payment method.",
          optional: true,
          mapping: true,
        },
        {
          id: "txn-type-column",
          label: "Transaction Type",
          value: "paymenttype",
          description:
            "Please select column which identifies transaction as forward, refund or partial refund.",
          optional: true,
          mapping: true,
        },
        {
          id: "amount-refunded-column",
          label: "Amount Refunded",
          value: "amount_refunded",
          description:
            "Please select column which identifies refunded amount.",
          optional: true,
          mapping: true,
        },
        {
          id: "payment-network-column",
          label: "Payment Network",
          value: "payment_network",
          description:
            "Please select column which identifies payment network.",
          optional: true,
          mapping: true,
        },
      ],
      txnTypeMap: {
        forward: "",
        refund: "",
        partial_refund: "",
      },
      dateOptions: [
        { text: "DD/MM/YYYY", value: "02/01/2006" },
        { text: "MM/DD/YYYY", value: "01/02/2006" },
        { text: "YYYY-MM-DD", value: "2006-01-02" },
        { text: "Month D, YYYY", value: "January 2, 2006" },
        { text: "D MMM, YYYY", value: "2 Jan, 2006" },
        { text: "D MMMM, YYYY", value: "2 January, 2006" },
        { text: "DD/MM/YY", value: "02/01/06" },
        { text: "MM/DD/YY", value: "01/02/06" },
        { text: "YY-MM-DD", value: "06-01-02" },
        { text: "D/M/YYYY", value: "2/1/2006" },
        { text: "D.M.YYYY", value: "2.1.2006" },
        { text: "D-MMM-YY", value: "2-Jan-06" },
        { text: "DD-MM-YYYY HH:mm:ss", value: "02-01-2006 15:04:05" },
        { text: "MM-DD-YYYY HH:mm:ss", value: "01-02-2006 15:04:05" },
        { text: "YYYY-MM-DD HH:mm:ss", value: "2006-01-02 15:04:05" },
        { text: "Month D, YYYY HH:mm:ss", value: "January 2, 2006 15:04:05" },
        { text: "D MMM, YYYY HH:mm:ss", value: "2 Jan, 2006 15:04:05" },
        { text: "D MMMM, YYYY HH:mm:ss", value: "2 January, 2006 15:04:05" },
        { text: "DD/MM/YY HH:mm:ss", value: "02/01/06 15:04:05" },
        { text: "MM/DD/YY HH:mm:ss", value: "01/02/06 15:04:05" },
        { text: "YY-MM-DD HH:mm:ss", value: "06-01-02 15:04:05" },
        { text: "D/M/YYYY HH:mm:ss", value: "2/1/2006 15:04:05" },
        { text: "D.M.YYYY HH:mm:ss", value: "2.1.2006 15:04:05" },
        { text: "D-MMM-YY HH:mm:ss", value: "2-Jan-06 15:04:05" },
        { text: "DD/MM/YYYY H:mm:ss PM", value: "02/01/2006 3:04:05 PM" },
        { text: "MM/DD/YYYY H:mm:ss PM", value: "01/02/2006 3:04:05 PM" },
        { text: "YYYY-MM-DD H:mm:ss PM", value: "2006-01-02 3:04:05 PM" },
        {
          text: "Month D, YYYY H:mm:ss PM",
          value: "January 2, 2006 3:04:05 PM",
        },
        { text: "D MMM, YYYY H:mm:ss PM", value: "2 Jan, 2006 3:04:05 PM" },
        {
          text: "D MMMM, YYYY H:mm:ss PM",
          value: "2 January, 2006 3:04:05 PM",
        },
        { text: "DD/MM/YY H:mm:ss PM", value: "02/01/06 3:04:05 PM" },
        { text: "MM/DD/YY H:mm:ss PM", value: "01/02/06 3:04:05 PM" },
        { text: "YY-MM-DD H:mm:ss PM", value: "06-01-02 3:04:05 PM" },
        { text: "D/M/YYYY H:mm:ss PM", value: "2/1/2006 3:04:05 PM" },
        { text: "D.M.YYYY H:mm:ss PM", value: "2.1.2006 3:04:05 PM" },
        { text: "D-MMM-YY H:mm:ss PM", value: "2-Jan-06 3:04:05 PM" },
        { text: "DD/MM/YYYY HH:mm:ss.SSS", value: "02/01/2006 15:04:05.000" },
        { text: "MM/DD/YYYY HH:mm:ss.SSS", value: "01/02/2006 15:04:05.000" },
        { text: "YYYY-MM-DD HH:mm:ss.SSS", value: "2006-01-02 15:04:05.000" },
        {
          text: "Month D, YYYY HH:mm:ss.SSS",
          value: "January 2, 2006 15:04:05.000",
        },
        { text: "D MMM, YYYY HH:mm:ss.SSS", value: "2 Jan, 2006 15:04:05.000" },
        {
          text: "D MMMM, YYYY HH:mm:ss.SSS",
          value: "2 January, 2006 15:04:05.000",
        },
        { text: "DD/MM/YY HH:mm:ss.SSS", value: "02/01/06 15:04:05.000" },
        { text: "MM/DD/YY HH:mm:ss.SSS", value: "01/02/06 15:04:05.000" },
        { text: "YY-MM-DD HH:mm:ss.SSS", value: "06-01-02 15:04:05.000" },
        { text: "D/M/YYYY HH:mm:ss.SSS", value: "2/1/2006 15:04:05.000" },
        { text: "D.M.YYYY HH:mm:ss.SSS", value: "2.1.2006 15:04:05.000" },
        { text: "D-MMM-YY HH:mm:ss.SSS", value: "2-Jan-06 15:04:05.000" },
        {
          text: "DD/MM/YYYY H:mm:ss.SSS PM",
          value: "02/01/2006 3:04:05.000 PM",
        },
        {
          text: "MM/DD/YYYY H:mm:ss.SSS PM",
          value: "01/02/2006 3:04:05.000 PM",
        },
        {
          text: "YYYY-MM-DD H:mm:ss.SSS PM",
          value: "2006-01-02 3:04:05.000 PM",
        },
        {
          text: "Month D, YYYY H:mm:ss.SSS PM",
          value: "January 2, 2006 3:04:05.000 PM",
        },
        {
          text: "D MMM, YYYY H:mm:ss.SSS PM",
          value: "2 Jan, 2006 3:04:05.000 PM",
        },
        {
          text: "D MMMM, YYYY H:mm:ss.SSS PM",
          value: "2 January, 2006 3:04:05.000 PM",
        },
        { text: "DD/MM/YY H:mm:ss.SSS PM", value: "02/01/06 3:04:05.000 PM" },
        { text: "MM/DD/YY H:mm:ss.SSS PM", value: "01/02/06 3:04:05.000 PM" },
        { text: "YY-MM-DD H:mm:ss.SSS PM", value: "06-01-02 3:04:05.000 PM" },
        { text: "D/M/YYYY H:mm:ss.SSS PM", value: "2/1/2006 3:04:05.000 PM" },
        { text: "D.M.YYYY H:mm:ss.SSS PM", value: "2.1.2006 3:04:05.000 PM" },
        { text: "D-MMM-YY H:mm:ss.SSS PM", value: "2-Jan-06 3:04:05.000 PM" },
        { text: "DD/MM/YYYY | HH:mm:ss", value: "02/01/2006 | 15:04:05" },
        { text: "MM/DD/YYYY | HH:mm:ss", value: "01/02/2006 | 15:04:05" },
        { text: "YYYY-MM-DD | HH:mm:ss", value: "2006-01-02 | 15:04:05" },
        {
          text: "Month D, YYYY | HH:mm:ss",
          value: "January 2, 2006 | 15:04:05",
        },
        { text: "D MMM, YYYY | HH:mm:ss", value: "2 Jan, 2006 | 15:04:05" },
        {
          text: "D MMMM, YYYY | HH:mm:ss",
          value: "2 January, 2006 | 15:04:05",
        },
        { text: "DD/MM/YY | HH:mm:ss", value: "02/01/06 | 15:04:05" },
        { text: "MM/DD/YY | HH:mm:ss", value: "01/02/06 | 15:04:05" },
        { text: "YY-MM-DD | HH:mm:ss", value: "06-01-02 | 15:04:05" },
        { text: "D/M/YYYY | HH:mm:ss", value: "2/1/2006 | 15:04:05" },
        { text: "D.M.YYYY | HH:mm:ss", value: "2.1.2006 | 15:04:05" },
        { text: "D-MMM-YY | HH:mm:ss", value: "2-Jan-06 | 15:04:05" },
        { text: "DD/MM/YYYY HH:mm:ss Z", value: "02/01/2006 15:04:05 Z" },
        { text: "MM/DD/YYYY HH:mm:ss Z", value: "01/02/2006 15:04:05 Z" },
        { text: "YYYY-MM-DD HH:mm:ss Z", value: "2006-01-02 15:04:05 Z" },
        {
          text: "Month D, YYYY HH:mm:ss Z",
          value: "January 2, 2006 15:04:05 Z",
        },
        { text: "D MMM, YYYY HH:mm:ss Z", value: "2 Jan, 2006 15:04:05 Z" },
        {
          text: "D MMMM, YYYY HH:mm:ss Z",
          value: "2 January, 2006 15:04:05 Z",
        },
        { text: "DD/MM/YY HH:mm:ss Z", value: "02/01/06 15:04:05 Z" },
        { text: "MM/DD/YY HH:mm:ss Z", value: "01/02/06 15:04:05 Z" },
        { text: "YY-MM-DD HH:mm:ss Z", value: "06-01-02 15:04:05 Z" },
        { text: "D/M/YYYY HH:mm:ss Z", value: "2/1/2006 15:04:05 Z" },
        { text: "D.M.YYYY HH:mm:ss Z", value: "2.1.2006 15:04:05 Z" },
        { text: "D-MMM-YY HH:mm:ss Z", value: "2-Jan-06 15:04:05 Z" },
        { text: "DD MMM 'YY | hh:mm a", value: "02 Mar '23 | 11:46 am" },
      ],
    };
  },

  computed: {
    convertCountryToCurrency() {
      return this.$store.getters["auth/getConvertCountry"];
    },

    stepsCount() {
      if (this.formType === "recon") {
        return `${this.formStep} / ${this.refundIncluded ? 5 : 4}`;
      } else {
        return `${this.formStep} / ${this.form.step2.status ||
          this.form.step2.paymenttype ||
          this.form.step2.paymentmethod
          ? 4
          : 3
          }`;
      }
    },

    columnsForHeaderMapping() {
      return this.formType === "recon"
        ? this.columnsForSettlement
        : this.columnsForTransaction;
    },
    showNextButton() {
      return (
        this.formStep === 1 ||
        this.formStep === 2 ||
        (this.formStep === 3 &&
          (this.formType === "recon" ||
            (this.formType === "transaction" &&
              (this.form.step2.status ||
                this.form.step2.paymenttype ||
                this.form.step2.paymentmethod)) ||
            this.refundIncluded)) ||
        (this.formStep === 4 && this.refundIncluded)
      );
    },
  },

  created() {
    this.form.step1.currency = this.convertCountryToCurrency;
  },

  watch: {
    showForm(value) {
      if (value) {
        this.$bvModal.show("config-form-modal");

        switch (this.formType) {
          case "recon":
            if(this.currentItem) {
              this.modalFormTitle = "Edit Settlement Config";
            } else {
              this.modalFormTitle = "Create Settlement Config";
            }
            break;

          case "transaction":
            if(this.currentItem) {
              this.modalFormTitle = "Edit Transaction Config";
            } else {
              this.modalFormTitle = "Create Transaction Config";
            }
            break;

          default:
            this.modalFormTitle = "";
            break;
        }

        if(this.currentItem) {
          this.editFileConfig();
        }

      } else {
        this.$bvModal.hide("config-form-modal");
      }
    },
  },

  methods: {
    openFormModal() {
      // Open form modal
      this.$bvModal.show("config-form-modal");
    },

    editFileConfig() {
      if(!this.currentItem) {
        this.$notify({
          title: "Error",
          text: "Please select a file config to edit.",
          type: "error",
        });
        return;
      }
      console.log(this.currentItem);
      const item = this.currentItem;

      this.form = {
        step1: {
          display_name: item.display_name,
          sheet_number: item.sheet_number,
          offset: item.offset,
          file: null,
        },
        step2: {
          date: item.header_mapping.date,
          amount: item.header_mapping.amount,
          status: item.header_mapping.status || item.header_mapping.txn_type,
          txn_ref: item.header_mapping.txn_ref,
          storeid: item.header_mapping.storeid || "",
          merchantref: item.header_mapping.merchantref || "",
          paymentmethod: item.header_mapping.paymentmethod || "",
          paymenttype: item.header_mapping.paymenttype || "",
          amount_refunded: item.header_mapping.amount_refunded || "",
          payment_network: item.header_mapping.payment_network || "",
        },
        step3: {
          date_format: item.source_date_format,
          payment_channel_key: item.payment_channel_key,
          refund_offset: item.refund_offset,
          refund_sheet_number: item.refund_sheet_number,
          currency_key: item.currency,
          timezone_key: this.timezoneToCountry(item.time_offset_minutes),
        },
        step4: {
          date: item.refund_header_mapping.date || "",
          amount: item.refund_header_mapping.amount || "",
          status: item.refund_header_mapping.txn_type || "",
          txn_ref: item.refund_header_mapping.txn_ref || "",
          status_code_mapping: {
            success_status: "",
            failed_status: "",
            pending_status: "",
          },
          payment_method_mapping: {
            credit_card: "",
            debit_card: "",
            net_banking: "",
            upi: "",
            wallet: "",
            cash: "",
            emi: "",
            pay_later: "",
          },
          transaction_type_mapping: {
            forward: "",
            refund: "",
            partial_refund: "",
          },
        },
        charge_params: {
          fee: item.charge_params.fee || "",
          tax: item.charge_params.tax || "",
          refund_fee: item.charge_params.refund_fee || "",
          refund_tax: item.charge_params.refund_tax || "",
        },
        all_headers: item.all_headers || [],
      };

      if(item.optional_mapping) {
        for(let key in item.optional_mapping) {
          if(key in this.form.step4) {
            for(let value in item.optional_mapping[key]) {
              if(item.optional_mapping[key][value] in this.form.step4[key]) {
                this.form.step4[key][item.optional_mapping[key][value]] = value;
              }
            }
          }
        }
      }

      if (
          !this.dateOptions.find(
            (value) => value.value === item.source_date_format
          )
        ) {
          this.dateOptions.push({
            text: item.source_date_format,
            value: item.source_date_format,
          });
        }

      this.fileHeaders = item.all_headers || [];

      if (Object.keys(item.refund_header_mapping).length > 0 && this.formType === "recon") {
        this.refundIncluded = true;
      }
      this.openFormModal("recon");
    },

    validateState(name, key) {
      const { $dirty, $error } = this.$v.form[key][name];
      const isvalid = $dirty ? !$error : null;
      return isvalid;
    },

    validateState2(name, item, key) {
      const { $dirty, $error } = this.$v.form[key][name][item];
      const isvalid = $dirty ? !$error : null;
      return isvalid;
    },

    timezoneToCountry(timezone) {
      for (const [key, value] of Object.entries(this.countryToTimezoneoffset)) {
        if (value * -60 === timezone) {
          return key;
        }
      }
    },

    resetForm() {
      this.form = {
        step1: {
          display_name: "",
          sheet_number: 0,
          offset: 0,
          file: null,
        },
        step2: {
          date: "",
          amount: "",
          status: "",
          txn_ref: "",
          storeid: "",
          merchantref: "",
          paymentmethod: "",
          paymenttype: "",
          amount_refunded: "",
          payment_network: ""
        },
        step3: {
          date_format: "",
          payment_channel_key: null,
          refund_offset: 0,
          refund_sheet_number: 0,
          currency_key: "INR",
          timezone_key: "Global",
        },
        step4: {
          date: "",
          amount: "",
          status: "",
          txn_ref: "",
          status_code_mapping: {
            success_status: "",
            failed_status: "",
            pending_status: "",
          },
          payment_method_mapping: {
            credit_card: "",
            debit_card: "",
            net_banking: "",
            upi: "",
            wallet: "",
            cash: "",
            emi: "",
            pay_later: "",
          },
          transaction_type_mapping: {
            forward: "",
            refund: "",
            partial_refund: "",
          },
        },
        charge_params: {
          fee: "",
          tax: "",
          refund_fee: "",
          refund_tax: "",
        },
      };
      this.formStep = 1;
      this.isSubmited = false;
      this.refundIncluded = false;
      this.fileHeaders = [];
      this.$v.$reset();
    },

    hideModal() {
      this.$emit("hide-form");
      this.resetForm();
    },

    async nextStep() {
      let formField = `step${this.formStep}`;

      if(formField in this.form) {
        this.$v.form[formField].$touch();

        if (this.$v.form[formField].$error) {
          this.$notify({
            type: "error",
            text: 'There are some errors in the form',
            closeOnClick: true,
          });
          return;
        }
      }

      if (this.formStep === 1) {
        await this.submitForAction("find_headers", 1);
        if(this.goToNext) {
          this.formStep = 2;
        } else {
          this.goToNext = true;
        }
        return;
      }

      if (this.formStep === 2) {
        await this.submitForAction("find_date_format", 2);
        if(this.goToNext) {
          this.formStep = 3;
        } else {
          this.goToNext = true;
        }
        return;
      }

      if (this.formStep === 3) {
        if (this.refundIncluded) {
          await this.submitForAction("find_headers", 3);
        }
        if(this.goToNext) {
          this.formStep = 4;
        } else {
          this.goToNext = true;
        }
        return;
      }

      if (this.formStep === 4 && this.refundIncluded) {
        this.formStep++;
        return;
      }
      this.isSubmited = true;
    },

    submitForAction(action, step) {
      // Actions allowed for step 1 and 2
      if (step != 1 && step != 2 && (step != 3 || !this.refundIncluded)) {
        return;
      }
      if (this.currentItem && !this.form.step1.file) {
        return;
      }
      const formData = new FormData();
      formData.append("file", this.form.step1.file);
      formData.append("offset", this.form.step1.offset);
      formData.append("sheet_number", this.form.step1.sheet_number);
      formData.append("action", action);
      if (step === 2) {
        const headerMapping = {
          date: this.form.step2.date,
          amount: this.form.step2.amount,
          status: this.form.step2.status,
          txn_ref: this.form.step2.txn_ref,
        };
        formData.append("header_mapping", JSON.stringify(headerMapping));
      }
      if (step === 3) {
        formData.append("offset", this.form.step3.refund_offset);
        formData.append("sheet_number", this.form.step3.refund_sheet_number);
      }
      return this.$store
        .dispatch(`fileManagement/${POST_FILE_CONFIG}`, { formData: formData })
        .then((response) => {
          if (step === 1 && response.data.headers) {
            // this.form.step1.file = null;
            this.fileHeaders = response.data.headers;

            // Update From data for Edit if new file uploaded
            if(this.currentItem) {
              // update file headers
              for(let key in this.form.step2) {
                if(!this.fileHeaders.includes(this.form.step2[key])) {
                  this.form.step2[key] = "";
                }
              }

              let pmtMethodMapping = {
                credit_card: "",
                debit_card: "",
                net_banking: "",
                upi: "",
                wallet: "",
                cash: "",
                emi: "",
                pay_later: "",
              }

              let txnTypeMapping = {
                forward: "",
                refund: "",
                partial_refund: "",
              };

              let statusCodeMapping = {
                success_status: "",
                failed_status: "",
                pending_status: "",
              }

              if(this.form.step2.paymentmethod) {
                pmtMethodMapping = this.form.step4.payment_method_mapping
              }

              if(this.form.step2.paymenttype) {
                txnTypeMapping = this.form.step4.transaction_type_mapping
              }

              if(this.form.step2.status) {
                statusCodeMapping = this.form.step4.status_code_mapping
              }

              this.form.step4 = {
                date: this.fileHeaders.includes(this.form.step4.date) ? this.form.step4.date: "",
                amount: this.fileHeaders.includes(this.form.step4.amount) ? this.form.step4.amount: "",
                status: this.fileHeaders.includes(this.form.step4.status) ? this.form.step4.status: "",
                txn_ref: this.fileHeaders.includes(this.form.step4.txn_ref) ? this.form.step4.txn_ref: "",
                status_code_mapping: statusCodeMapping,
                payment_method_mapping: pmtMethodMapping,
                transaction_type_mapping: txnTypeMapping,
              };

              //update charge params
              for(let key in this.form.charge_params) {
                if(!this.fileHeaders.includes(this.form.charge_params[key])) {
                  this.form.charge_params[key] = "";
                }
              }
            }
          }

          if (step === 2 && response.data.date_format) {
            if (
              !this.dateOptions.find(
                (value) => value.value === response.data.date_format
              )
            ) {
              this.dateOptions.push({
                text: response.data.date_format,
                value: response.data.date_format,
              });
            }
            this.form.step3.date_format = response.data.date_format;
          }

          if (step === 3 && response.data.headers) {
            this.refundHeaders = response.data.refund_headers;
          }
        })
        .catch(error => {
          this.goToNext = false;
          this.errorNotify(error);
        });
    },

    submitFormData() {
      let formField = `step${this.formStep}`;

      if(formField in this.form) {
        this.$v.form[formField].$touch();

        if (this.$v.form[formField].$error) {
          this.$notify({
            type: "error",
            text: 'There are some errors in the form',
            closeOnClick: true,
          });
          return;
        }
      }

      let chargeParams = {};
      let optionalMapping = {};
      let headerMapping = {};

      if (this.formType === "transaction") {
        // handle transaction related logic
        headerMapping = {
          amount: this.form.step2.amount,
          date: this.form.step2.date,
          txn_ref: this.form.step2.txn_ref,
        }
        if (this.form.step2.storeid) {
          headerMapping.storeid = this.form.step2.storeid
        }
        if (this.form.step2.merchantref) {
          headerMapping.merchantref = this.form.step2.merchantref
        }
        if (this.form.step2.paymentmethod) {
          headerMapping.paymentmethod = this.form.step2.paymentmethod
        }
        if (this.form.step2.status) {
          headerMapping.status = this.form.step2.status
        }
        if (this.form.step2.paymenttype) {
          headerMapping.paymenttype = this.form.step2.paymenttype
        }
        if (this.form.step2.amount_refunded) {
          headerMapping.amount_refunded = this.form.step2.amount_refunded
        }
        if (this.form.step2.payment_network) {
          headerMapping.payment_network = this.form.step2.payment_network
        }

        optionalMapping.status_code_mapping = {};
        optionalMapping.payment_method_mapping = {};
        optionalMapping.transaction_type_mapping = {};

        for (let status in this.form.step4.status_code_mapping) {
          if(this.form.step4.status_code_mapping[status]) {
            optionalMapping.status_code_mapping[this.form.step4.status_code_mapping[status]] = status;
          }
        }

        for (let paymentMethod in this.form.step4.payment_method_mapping) {
          if(this.form.step4.payment_method_mapping[paymentMethod]) {
            optionalMapping.payment_method_mapping[this.form.step4.payment_method_mapping[paymentMethod]] = paymentMethod;
          }
        }

        for (let transactionType in this.form.step4.transaction_type_mapping) {
          if(this.form.step4.transaction_type_mapping[transactionType]) {
            optionalMapping.transaction_type_mapping[this.form.step4.transaction_type_mapping[transactionType]] = transactionType;
          }
        }
      } else {
        // Handle settlement related logic
        headerMapping = {
          date: this.form.step2.date,
          amount: this.form.step2.amount,
          txn_ref: this.form.step2.txn_ref,
          txn_type: this.form.step2.status,
        };
      }

      if (this.form.charge_params.fee) {
        chargeParams.fee = this.form.charge_params.fee;
      }

      if (this.form.charge_params.tax) {
        chargeParams.tax = this.form.charge_params.tax;
      }
      
      const payload = {
        // Source Name should be empty for new config
        source_type: this.formType === "transaction" ? "MERCHANT" : "PROVIDER",
        header_mapping: headerMapping,
        charge_params: chargeParams,
        offset: this.form.step1.offset,
        sheet_number: this.form.step1.sheet_number,
        source_date_format: this.form.step3.date_format,
        refund_header_mapping: {},
        file_type: this.formType === "recon" ? "recon" : "transaction",
        display_name: this.form.step1.display_name,
        currency: this.form.step3.currency_key,
        time_offset_minutes:
          this.countryToTimezoneoffset[this.form.step3.country_key],
        payment_channel_key: this.form.step3.payment_channel_key,
        refund_charge_params: {},
        all_headers: this.fileHeaders,
        optional_mapping: optionalMapping,
      };

      if (this.refundIncluded) {
        payload.refund_header_mapping = {
          date: this.form.step4.date,
          amount: this.form.step4.amount,
          txn_ref: this.form.step4.txn_ref,
          txn_type: this.form.step4.status,
        };

        if (this.form.charge_params.refund_fee) {
          payload.refund_charge_params.fee = this.form.charge_params.refund_fee;
        }

        if (this.form.charge_params.refund_tax) {
          payload.refund_charge_params.tax = this.form.charge_params.refund_tax;
        }

        payload["refund_sheet_number"] = this.form.step4.refund_sheet_number;
        payload["refund_offset"] = this.form.step4.refund_offset;
      }

      this.processing = true;
      this.$store
        .dispatch(`fileManagement/${POST_FILE_MANAGEMENT_DATA}`, {
          formData: payload,
          currentData: this.currentItem,
        })
        .then((response) => {
          this.$notify({
            type: "success",
            text: response.message,
            closeOnClick: true,
          });
          this.hideModal();
        })
        .catch(this.errorNotify)
        .finally(() => this.processing = false);
    },

    errorNotify(error) {
      const err = JSON.parse(error.message);
      this.$notify({
        type: "error",
        text: err.message,
        closeOnClick: true,
      });
    },

    flagImage(currency) {
      return this.currencyToFlagMapping[currency] || null;
    },
  },
};
</script>

<template>
  <b-modal id="config-form-modal" @show="resetForm" @hidden="hideModal" no-close-on-backdrop :title="modalFormTitle">
    <!-- Basic File details-->
    <div v-if="formStep === 1">
      <div class="d-flex justify-content-between h5 mb-0">
        <p>File Details</p>
        {{ stepsCount }}
      </div>
      <!-- Ask for file name -->
      <b-form-group id="file-name-group" label-for="display-name" description="Name to be displayed on the recon dropdown"
        invalid-feedback="This is a required field.">
        <template #label>
          Display Name <span class="text-danger">*</span>
        </template>
        <b-form-input id="display-name" name="display-name" v-model="form.step1.display_name"
          :state="validateState('display_name', 'step1')" aria-describedby="display-name-live-feedback"></b-form-input>
      </b-form-group>

      <!-- Ask for file offset -->
      <b-form-group id="file-offset-group" label-for="file-offset"
        description="Number of rows to skip before reading the file" invalid-feedback="This is a required field.">
        <template #label>
          File Offset <span class="text-danger">*</span>
        </template>
        <b-form-input id="file-offset" name="file-offset" v-model.number="form.step1.offset"
          :state="validateState('offset', 'step1')" aria-describedby="file-offset-live-feedback"
          type="number"></b-form-input>
      </b-form-group>

      <!-- Ask for file sheet number -->
      <b-form-group id="file-sheet-number-group" label-for="file-sheet-number"
        description="Sheet number to read from the file" invalid-feedback="This is a required field.">
        <template #label>
          Sheet Number <span class="text-danger">*</span>
        </template>
        <b-form-input id="file-sheet-number" name="file-sheet-number" v-model="form.step1.sheet_number"
          :state="validateState('sheet_number', 'step1')" aria-describedby="file-sheet-number-live-feedback"
          type="number"></b-form-input>
      </b-form-group>

      <!-- Ask for file -->
      <b-form-group id="file-group" label-for="file" description="Upload the file to be configured"
        invalid-feedback="This is a required field.">
        <template #label> File <span class="text-danger" v-if="!currentItem">*</span> </template>
        <b-form-file id="file" name="file" v-model="form.step1.file" :state="validateState('file', 'step1')"
          aria-describedby="file-live-feedback"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"></b-form-file>
      </b-form-group>
    </div>

    <!-- Header Mapping -->
    <div v-if="formStep === 2">
      <div class="d-flex justify-content-between h5 mb-0">
        <p>Settlement File Header Mapping</p>
        {{ stepsCount }}
      </div>

      <b-form-group v-for="(column, index) in columnsForHeaderMapping" :key="index" :id="column.id" :label="column.label"
        :label-for="column.id" :description="column.description" invalid-feedback="This is a required field.">
        <template v-if="!column.optional" #label>
          {{ column.label }} <span class="text-danger">*</span>
        </template>

        <!-- Dropdown to select column -->
        <b-form-select v-model="form.step2[column.value]" class="form-control"
          :state="validateState(column.value, 'step2')" :aria-describedby="column.id + '-live-feedback'"
          :options="fileHeaders">
          <template #first>
            <b-form-select-option :value="null">-- Please select {{ column.label }} column
              --</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </div>

    <!-- Other details -->
    <div v-if="formStep === 3">
      <div class="d-flex justify-content-between h5 m-0">
        <p>Other Details</p>
        {{ stepsCount }}
      </div>

      <!-- Date format -->
      <b-form-group id="date-input-group" label="Date Format" label-for="date-input"
        description="Please make sure that the file date format is correctly represented for Mon, 02 Jan 2006 15:04:05 MST."
        invalid-feedback="This is a required field.">
        <template #label>
          Date Format <span class="text-danger">*</span>
        </template>
        <b-form-select id="date-input" name="date-input" v-model="form.step3.date_format" :options="dateOptions"
          :state="validateState('date_format', 'step3')" aria-describedby="date-input-live-feedback"></b-form-select>
      </b-form-group>

      <!-- Timezone selection -->
      <b-form-group id="timezone-group" label-for="timezone" description="Timezone of the settlement file."
        invalid-feedback="This is a required field.">
        <template #label>
          Timezone <span class="text-danger">*</span>
        </template>
        <b-form-select id="timezone" name="timezone" v-model="form.step3.timezone_key" :options="countries"
          :state="validateState('timezone_key', 'step3')" aria-describedby="timezone-live-feedback"></b-form-select>
      </b-form-group>

      <!-- Payment channel selection -->
      <b-form-group id="payment-channel-group" label-for="payment-channel"
        description="Payment channel of the settlement file." invalid-feedback="This is a required field.">
        <template #label>
          Payment Channel <span class="text-danger">*</span>
        </template>
        <b-form-select id="payment-channel" name="payment-channel" v-model="form.step3.payment_channel_key"
          :options="paymentChannels" :state="validateState('payment_channel_key', 'step3')"
          aria-describedby="payment-channel-live-feedback"></b-form-select>
      </b-form-group>

      <!-- Currency selection -->
      <b-form-group id="currency-group" label-for="currency" description="Currency of the transactions."
        invalid-feedback="This is a required field.">
        <template #label>
          Currency <span class="text-danger">*</span>
        </template>
        <!-- Container for Image and Select Field -->
        <!-- Flag Image -->
        <img v-if="flagImage(form.step3.currency_key)" class="flag_images" :src="flagImage(form.step3.currency_key)"
          alt="Selected currency flag" />

        <!-- Currency Dropdown -->
        <b-form-select id="currency" name="currency" class="form-control custom-select-for-flag"
          v-model="form.step3.currency_key" :options="currencies" :state="validateState('currency_key', 'step3')"
          aria-describedby="currency-live-feedback">
          <b-form-select-option value="">{{
            "Select Currency"
          }}</b-form-select-option>
          <b-form-select-option v-for="currency in currencies" :key="currency" :value="currency">
            {{ currency }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <!-- Checkbox for refunds -->
      <b-form-group v-if="formType === 'recon'" id="refund-included-group" label="Refund Included"
        label-for="refund-included" description="Are refunds supported for this configuration?">
        <b-form-checkbox id="refund-included" name="refund-included" v-model="refundIncluded" :value="true"
          :unchecked-value="false">
          Yes
        </b-form-checkbox>
      </b-form-group>

      <!--  Refund offset if refunds are supported -->
      <b-form-group v-if="refundIncluded" id="refund-offset-group" label="Refund Offset" label-for="refund-offset-input"
        invalid-feedback="This is a required field and must be positive integer.">
        <p class="text-muted font-size-12">
          Refund offset of the transaction file.
        </p>
        <b-form-input id="refund-offset-input" v-model.number="form.step3.refund_offset" type="number"
          placeholder="Refund Offset" :state="validateState('refund_offset', 'step3')" required
          aria-describedby="refund-offset-live-feedback"></b-form-input>
      </b-form-group>

      <!-- Refund sheet number if refunds are supported -->
      <b-form-group v-if="refundIncluded" id="refund-sheet-no-group" label="Refund Sheet Number"
        label-for="refund-sheet-no-input" description="Refund sheet number of the transaction file."
        invalid-feedback="This is a required field and must be positive integer.">
        <b-form-input id="refund-sheet-no-input" v-model.number="form.step3.refund_sheet_number" type="number"
          placeholder="Refund Sheet Number" :state="validateState('refund_sheet_number', 'step3')" required
          aria-describedby="refund-sheet-no-live-feedback"></b-form-input>
      </b-form-group>
    </div>

    <!-- Mappings for optional -->
    <div v-if="formStep === 4 && formType === 'transaction'">
      <div class="d-flex justify-content-between h5 mb-0">
        <p>Mapping for optional fields</p>
        {{ stepsCount }}
      </div>

      <!-- Status Code Mapping -->
      <b-form-group v-if="form.step2.status" label="Status Code Mapping" label-for="status-code-table"
        description="Status code mapping for the transactions.">
        <b-table-simple id="status-code-table" bordered style="height: fit-content">
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Status Code</b-th>
              <b-th>Value</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(status, index) in status_code_mapping" :key="index">
              <!-- 1. Required transaction status name -->
              <b-td>
                <label>{{ status.name }} <span class="text-danger" v-if="!status.optional">*</span></label>
              </b-td>
              <!-- 2. Input field for status-->
              <b-td>
                <b-form-input v-model="form.step4.status_code_mapping[status.id]" :state="validateState2('status_code_mapping', status.id, 'step4')"
                  :aria-describedby="status.id + '-live-feedback'"
                  :id="status.id" :name="status.id" :placeholder="status.name" />

                <b-form-invalid-feedback :id="status.id + '-live-feedback'">
                  This is a required field.
                </b-form-invalid-feedback>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form-group>

      <!-- Payment Method Mapping -->
      <b-form-group v-if="form.step2.paymentmethod" label="Payment Method Mapping"
        label-for="payment-method-table" description="Payment method mapping for the transactions.">
        <b-table-simple id="payment-method-table" bordered style="height: fit-content">
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Payment Method</b-th>
              <b-th>Value</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(payment, index) in payment_method_mapping" :key="index">
              <!-- 1. Required payment method name -->
              <b-td>
                <label>
                  {{ payment.payment_method }}
                </label>
              </b-td>
              <!-- 2. Input field for payment method-->
              <b-td>
                <b-form-input v-model="form.step4.payment_method_mapping[payment.id]" :id="payment.id" :name="payment.id"
                  :placeholder="payment.payment_method" />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form-group>

      <!-- Transaction Type Mapping -->
      <b-form-group v-if="form.step2.paymenttype" label="Transaction Type Mapping" label-for="transaction-type-table"
        description="Transaction type mapping for the transactions.">
        <b-table-simple id="transaction-type-table" bordered style="height: fit-content">
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Transaction Type</b-th>
              <b-th>Value</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(transaction, index) in transaction_type_mapping" :key="index">
              <!-- 1. Required transaction type name -->
              <b-td>
                <label>
                  {{ transaction.transaction_type }} <span class="text-danger" v-if="!transaction.optional">*</span>
                </label>
              </b-td>
              <!-- 2. Input field for transaction type-->
              <b-td>
                <b-form-input v-model="form.step4.transaction_type_mapping[transaction.id]" :aria-describedby="transaction.id + '-live-feedback'" :state="validateState2('transaction_type_mapping', transaction.id, 'step4')"
                  :id="transaction.id" :name="transaction.id" :placeholder="transaction.transaction_type" />
                  <b-form-invalid-feedback :id="transaction.id + '-live-feedback'">
                    This is a required field.
                  </b-form-invalid-feedback>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form-group>
    </div>

    <!-- Refund File Headers -->
    <div v-if="formStep === 4 && refundIncluded && formType === 'recon'">
      <div class="d-flex justify-content-between h5 mb-0">
        <p>Refund File Headers</p>
        {{ stepsCount }}
      </div>

      <b-form-group v-for="(column, index) in columnsForHeaderMapping" :key="index" :id="column.id" :label="column.label"
        :label-for="column.id" :description="column.description" invalid-feedback="This is a required field.">
        <template v-if="!column.optional" #label>
          {{ column.label }} <span class="text-danger">*</span>
        </template>

        <!-- Dropdown to select column -->
        <b-form-select v-model="form.step4[column.value]" class="form-control"
          :state="validateState(column.value, 'step4')" :aria-describedby="column.id + '-live-feedback'"
          :options="fileHeaders">
          <template #first>
            <b-form-select-option :value="null">-- Please select {{ column.label }} column
              --</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </div>

    <!-- Charge Params -->
    <div v-if="formStep === 5 || (formStep === 4 && !refundIncluded && formType === 'recon')">
      <div class="d-flex justify-content-between h5 mb-0">
        <p>Charge Params</p>
        {{ stepsCount }}
      </div>

      <b-form-group id="fee-field-group" label="Fee Field" label-for="fee-field"
        description="Fee field of the settlements.">
        <b-form-select id="fee-field" name="fee-field" v-model="form.charge_params.fee"
          :options="fileHeaders">
          <template #first>
            <b-form-select-option :value="null">-- Please select Fee Field --</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group id="tax-field-group" label="Tax Field" label-for="tax-field"
        description="Tax field of the settlements.">
        <b-form-select id="tax-field" name="tax-field" v-model="form.charge_params.tax"
          :options="fileHeaders">
          <template #first>
            <b-form-select-option :value="null">-- Please select Tax Field --</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group v-if="refundIncluded" id="fee-field-group" label="Fee Field" label-for="fee-field"
        description="Fee field of the refunds.">
        <b-form-select id="fee-field" name="fee-field" v-model="form.charge_params.refund_fee"
          :options="fileHeaders">
          <template #first>
            <b-form-select-option :value="null">-- Please select Fee Field --</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group v-if="refundIncluded" id="tax-field-group" label="Tax Field" label-for="tax-field">
        <p class="text-muted font-size-12">Tax field of the refunds.</p>
        <b-form-select id="tax-field" name="tax-field" v-model="form.charge_params.refund_tax"
          :options="fileHeaders">
          <template #first>
            <b-form-select-option :value="null">-- Please select Tax Field --</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </div>

    <template #modal-footer>
      <div class="mx-auto">
        <b-btn variant="light" class="mr-2" v-if="formStep > 1" @click="() => (formStep = formStep - 1)">Previous</b-btn>
        <b-btn variant="primary" v-if="showNextButton" @click="nextStep">Next</b-btn>
        <b-btn variant="primary" :disabled="processing" v-if="!showNextButton" @click="submitFormData">Submit</b-btn>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>
::v-deep .flag_images {
  position: absolute;
  width: 1.5rem;
  margin-top: 0.7rem;
  margin-left: 0.5rem;
}

::v-deep .custom-select-for-flag {
  padding-left: 2.5rem;
}
</style>