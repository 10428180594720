<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import TooltipTable from '@/components/tooltip-table.vue';

import {
  FETCH_FILE_MANAGEMENT_DATA,
  FETCH_ALL_PAYMENT_CHANNEL,
} from "@/state/actions.type";
import cloneDeep from 'lodash.clonedeep';
import FileManagementForm from './file-management-form.vue';
export default {
  components: {
    // Layout,
    // PageHeader,
    TooltipTable,
    FileManagementForm,
  },

  data() {
    return {
      fields: [
        { key: "display_name", label: "Display Name" },
        { key: "file_type", label: "Config Type" },
        { key: "charge_params", label: "Charge Params" },
        { key: "header_mapping", label: "Header Mapping" },
        { key: "offset", label: "Offset" },
        { key: "sheet_number", label: "Sheet No" },
        { key: "refund_charge_params", label: "Refund Charge Params" },
        { key: "refund_header_mapping", label: "Refund Header Mapping" },
        { key: "refund_offset", label: "Refund Offset" },
        { key: "refund_sheet_number", label: "Refund Sheet No" },
        { key: "time_offset_minutes", label: "Time offset in minutes" },
        { key: "source_date_format", label: "Source Data Format" },
        { key: "source_name", label: "File Name" },
        { key: "action", label: "Action", stickyColumn: true },
      ],
      modalTableData: null,
      modalTitle: "",
      formType: "",
      paymentChannelList: [],
      formPaymentChannelList: [],
      selectedPSP: null,
      showForm: false,
      currentItem: null
    }
  },
  computed: {
    items() {
      return [
        {
          text: "Reconciliation",
          active: true,
        },
        {
          text: "File Management",
          active: true,
        },
      ];
    },

    fileManagementDataFromStore() {
      return this.$store.state.fileManagement.content;
    },
  },

  created() {
    this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, {
      country: null,
      paymentMethod: null
    }).then((response) => {
      this.paymentChannelList = response.filter((channel) => channel.is_enabled).map(pc => {
        return { text: pc['name'], value: pc['key'] }
      });
      this.formPaymentChannelList = cloneDeep(this.paymentChannelList);
      this.paymentChannelList.push({ text: 'All', value: 'All' });
      this.selectedPSP = this.paymentChannelList[this.paymentChannelList.length - 1].value;
    });
  },

  methods: {
    fetchFileManagement() {
      this.$store.dispatch(`fileManagement/${FETCH_FILE_MANAGEMENT_DATA}`, this.selectedPSP)
        .catch(this.errorNotify);
    },

    hideForm() {
      this.fetchFileManagement();
      this.formType = "";
      this.currentItem = null;
      this.showForm = false;
    },

    changeModalTableData(data, title) {
      this.modalTableData = data;
      this.modalTitle = title;
    },

    errorNotify(error) {
      const err = JSON.parse(error.message);
      this.$notify({
        type: "error",
        text: err.message,
        closeOnClick: true,
      });
    },

    editFileConfig(item) {
      this.currentItem = item;
      this.openFormModal(item.file_type);
    },

    openFormModal(type) {
      this.formType = type;
      this.showForm = true;
    },
  }
}
</script>

<template>
  <!-- <Layout> -->
    <!-- <PageHeader title="File Management" :items="items" /> -->

    <div>
      <b-row>
        <b-col md="9">
          <b-form-select id="payment-channel" name="payment-channel" class="w-25" v-model="selectedPSP"
            :options="paymentChannelList" @input="fetchFileManagement"></b-form-select>
        </b-col>
        <b-col>
          <!-- Add button to configure transaction file -->
          <b-btn variant="primary" size="sm" class="float-right mb-4 ml-2" @click="openFormModal('recon')">
            + Settlement File
          </b-btn>
          <b-btn variant="primary" size="sm" class="float-right mb-4" @click="openFormModal('transaction')">
            + Transaction File
          </b-btn>
        </b-col>
      </b-row>
  
      <!-- Table for displaying file config -->
      <div class="table-responsive text-center table-custom">
        <b-table class="table-centered" :items="fileManagementDataFromStore" :fields="fields" responsive="sm"
          :bordered="false" :small="true" sticky-header="500px" show-empty :empty-text="$t('common.no-records')"
          busy.sync="true" hover>
          <template v-slot:cell(source_date_format)="{ value }">
            {{ value }}
          </template>
          <template v-slot:cell(file_type)="{ value }">
            <p class="mb-0">{{ value === 'recon' ? 'Settlement' : 'Transaction' }}</p>
          </template>
          <template v-slot:cell(charge_params)="{ item, value }">
            <img src="@/assets/images/threedot.png" :id="`charge_param_${item.index}`"
              @click="changeModalTableData(value, 'Charge Params')" style="width: 15px; cursor: pointer;" alt
              v-b-modal.table-data-modal>
            <TooltipTable :target="`charge_param_${item.index}`" :items="value" />
          </template>
          <template v-slot:cell(refund_charge_params)="{ item, value }">
            <img src="@/assets/images/threedot.png" :id="`refund_charge_param_${item.index}`"
              @click="changeModalTableData(value, 'Refund Charge Params')" style="width: 15px; cursor: pointer;" alt
              v-b-modal.table-data-modal>
            <TooltipTable :target="`refund_charge_param_${item.index}`" :items="value" />
          </template>
          <template v-slot:cell(header_mapping)="{ item, value }">
            <img src="@/assets/images/threedot.png" :id="`header_mapping_${item.index}`"
              @click="changeModalTableData(value, 'Header Mapping')" style="width: 15px; cursor: pointer;" alt
              v-b-modal.table-data-modal>
            <TooltipTable :target="`header_mapping_${item.index}`" :items="value" />
          </template>
          <template v-slot:cell(refund_header_mapping)="{ item, value }">
            <img src="@/assets/images/threedot.png" :id="`refund_header_mapping_${item.index}`"
              @click="changeModalTableData(value, 'Refund Header Mapping')" style="width: 15px; cursor: pointer;" alt
              v-b-modal.table-data-modal>
            <TooltipTable :target="`refund_header_mapping_${item.index}`" :items="value" />
          </template>
          <template v-slot:cell(action)="{ item }">
            <b-btn variant="light" size="sm" @click="editFileConfig(item)"
              v-b-modal.config-settlement-form-modal>Edit</b-btn>
          </template>
        </b-table>
      </div>
  
      <FileManagementForm
        :showForm="showForm"
        :formType="formType"
        :paymentChannels="formPaymentChannelList"
        :selectedPaymentChannel="selectedPSP"
        :currentItem="currentItem"
        @hide-form="hideForm" />
  
      <b-modal id="table-data-modal" :title="modalTitle" no-close-on-backdrop hide-footer centered>
        <b-table-simple header-border-variant="light" header-class="border-bottom-0">
          <b-tbody>
            <b-tr v-for="(item, key) in modalTableData" :key="key">
              <b-td>{{ key }}</b-td>
              <b-td>{{ item }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-modal>
    </div>
  <!-- </Layout> -->
</template>

<style scoped>
.cursor-pointer{
  cursor: pointer;
}
::v-deep .flag_images{
  position: absolute;
  width: 20px;
  top: 39px;
  left: 10px;
}
::v-deep .custom-select-for-flag{
  padding-left:35px ;
}
::v-deep .b-table-sticky-column:last-child {
  right: 0;
}
::v-deep .btn{
  border-radius: 4px;
}
.form-control{
  font-size: 14px;
}
::v-deep label{
  font-size: 14px;
}
.h5{
  font-weight: 400;
  font-family: inherit;
}
</style>
